import React from "react"
import styled from "styled-components"
import map from "lodash/map"
import { motion, AnimateSharedLayout } from "framer-motion"

import { device } from "utils"

const Toggle = ({ options, onChange, selected }) => {
  const count = Object.keys(options).length

  return (
    <AnimateSharedLayout>
      <StyledToggle count={count} className="toggle">
        {map(options, (option, key) => (
          <ToggleButton
            key={key}
            selected={selected}
            handleClick={onChange}
            option={option}
          />
        ))}
      </StyledToggle>
    </AnimateSharedLayout>
  )
}

const StyledToggle = styled.div`
  background: white;
  border-radius: 32px;
  box-shadow: 0px 16px 48px rgba(61, 65, 70, 0.08);
  display: grid;
  grid-template-columns: ${({ count }) => `repeat(${count}, auto)`};
  padding: var(--sp-4);

  @media ${device.laptop} {
    position: relative;
    padding: var(--sp-8);
  }
`

const ToggleButton = ({ selected, option, handleClick }) => {
  const isSelected = selected === option.id

  const selectedStyles = {
    layoutId: "selected",
    initial: false, // important for flicker
    animate: {
      background: "var(--spruce)",
    },
    transition: {
      type: "spring",
      stiffness: 500,
      damping: 35,
      duration: 1,
    },
  }

  return (
    <Button onClick={() => handleClick(option)}>
      {isSelected && <motion.div {...selectedStyles} className="background" />}
      <motion.span
        initial={false}
        // animate={{ color: isSelected ? "#FFFFFF" : "#989998" }}
        animate={{ color: isSelected ? "#FFFFFF" : "#524A447A" }}
        className="text"
      >
        {option.text}
      </motion.span>
    </Button>
  )
}

const Button = styled.button`
  text-align: center;
  border-radius: 32px;
  position: relative;
  cursor: pointer;
  padding: var(--sp-8) var(--sp-12);

  .text {
    font-size: 10px;
    font-weight: 700;
    position: relative;
    z-index: 2;
    letter-spacing: 0.08em;
    text-transform: uppercase;

    @media ${device.mobileM} {
      font-size: 12px;
    }

    @media ${device.laptop} {
      font-size: 14px;
    }
  }

  .background {
    z-index: 1;
    border-radius: 32px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    box-shadow: 2px 2px 8px rgba(61, 65, 70, 0.16);
  }
`

export default Toggle
