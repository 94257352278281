import React from "react"
import styled from "styled-components"

import { device } from "utils"

const BottomCTA = ({ hat, title, subtitle, hideSun, colors }) => {
  return (
    <StyledBottomCTA className="bottomCTA">
      <h2>bottom cta</h2>
    </StyledBottomCTA>
  )
}

const StyledBottomCTA = styled.div`
  padding-left: var(--section-padding-left);
  padding-right: var(--section-padding-right);
  position: relative;

  .ctaCard {
    position: relative;
    z-index: 3;
    /* --tg-title-color: var(--primary-font-color); */
    /* --tg-subtitle-color: var(--primary-font-color); */
    background: #02132b;

    border-radius: var(--card-radius);
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr;

    grid-gap: var(--sp-32);
    padding: var(--sp-24);
    border-radius: var(--sp-24);
    text-align: center;

    @media ${device.laptop} {
      border-radius: var(--card-radius);
      padding: var(--sp-80) 0;
    }
  }

  .tgWrap {
    text-align: center;
    .subtitle {
      max-width: 720px;
      margin: 0 auto;
    }
  }
`

BottomCTA.defaultProps = {
  hideSun: false,
}

export default BottomCTA
