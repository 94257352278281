import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import { Waypoint } from "react-waypoint"
import { motion } from "framer-motion"

import { Link, Media } from "components/common"
import { device } from "utils"

import DesktopHeader from "src/components/navigation/DesktopHeader"
import MobileHeader from "src/components/navigation/MobileHeader"

const Nav = ({ simple }) => {
  const [isSticky, setIsSticky] = useState(false)
  const { primaryNav, mobile_nav } = useStaticQuery(NAV_QUERY)

  const variants = {
    sticky: {
      boxShadow:
        "0px 8px 32px rgba(233, 222, 216, 0.48), inset 0px 2px 8px rgba(249, 249, 249, 0.2)",
      backdropFilter: "blur(16px)",
      WebkitBackdropFilter: "blur(16px)",
      background: "rgba(255, 255, 255, 0.64)",
    },
    static: {
      boxShadow:
        "0px 0px 0px rgba(255, 255, 255, 0), inset 0px 0px 0px rgba(255, 255, 255, 0)",
      WebkitBackdropFilter: "blur(0px)",
      backdropFilter: "blur(0px)",
      background: "rgba(255, 255, 255, 0)",
    },
  }

  const onPositionChange = ({ currentPosition, previousPosition }) => {
    if (currentPosition === "above") {
      setIsSticky(true)
    }

    if (previousPosition === "above" && currentPosition === "inside") {
      setIsSticky(false)
    }
  }

  if (simple) {
    return (
      <nav style={{ padding: "32px" }}>
        <Link to="/">
          <Media media={primaryNav.logo} />
        </Link>
      </nav>
    )
  }
  return (
    <>
      <Waypoint onPositionChange={onPositionChange} />
      <NavWrap
        className={isSticky ? "sticky" : "static"}
        variants={variants}
        initial="static"
        animate={isSticky ? "sticky" : "static"}
      >
        <DesktopHeader {...primaryNav} />
        <MobileHeader {...mobile_nav} />
      </NavWrap>
    </>
  )
}

const NavWrap = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;

  background: var(--nav-background-color);
  padding: var(--section-mobile-padding) var(--section-mobile-padding-left);

  @-moz-document url-prefix() {
    transition: background 0.1s ease-in-out;

    &.sticky {
      background: rgba(255, 255, 255, 0.9) !important;
    }
  }

  @media ${device.laptop} {
    padding: var(--sp-32) var(--sp-48);
  }
`

export const NAV_QUERY = graphql`
  {
    primaryNav: contentfulNavigation(navigationId: { eq: "primary_nav" }) {
      ...Navigation
    }
    mobile_nav: contentfulNavigation(navigationId: { eq: "mobile_nav" }) {
      ...Navigation
    }
  }
`

export default Nav
