import { css } from "styled-components"

// https://css-irl.info/animating-underlines/
// https://www.metalab.com/blog

export default css`
  .animated_underline {
    background: var(--primary-gradient);
    /* 1px = size of line adjust on hover also... */
    background-size: 0% 2px;
    /* background-position: 100% 100%; */

    /* top to bottom, above is bottom to top */
    background-position: 0% 100%;
    background-repeat: no-repeat;
    transition: background-size 0.5s ease-in-out 0.1s;
  }

  &:hover {
    /* add this if hover is parent */
    .animated_underline {
      background-size: 100% 2px;
    }
  }
`
