import React from "react"
import styled from "styled-components"
import map from "lodash/map"

import { Link, Media, TextGroup } from "components/common"

import { useContentfulComponent, device } from "utils"

const DesktopFooter = ({ navigation, logo, components }) => {
  const { social_vps } = useContentfulComponent(components)

  return (
    <Footer>
      <ul className="container">
        <li className="logo">
          <Link to="/" className="logo-link">
            <Media media={logo} />
          </Link>
          <ul className="social-list">
            {map(social_vps.valueProps, ({ href, media, title, id }) => (
              <li key={id}>
                <Link href={href}>
                  <Media className="socialIcon" media={media} />
                  <TextGroup subtitle={title} />
                </Link>
              </li>
            ))}
          </ul>
        </li>
        <ul className="navigation-list">
          {map(navigation, (props) => (
            <ul key={props.id}>
              <li className="category">{props.category}</li>
              {map(props.navigation, ({ href, text, to }) => (
                <li key={text}>
                  <Link to={to} href={href} activeClassName="active">
                    {text}
                  </Link>
                </li>
              ))}
            </ul>
          ))}
        </ul>
      </ul>
    </Footer>
  )
}

const Footer = styled.footer`
  position: relative;
  z-index: 2;
  background: var(--footer-background-color);
  padding: var(--sp-80) var(--section-mobile-padding);

  .container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: var(--sp-56);
    width: 100%;
    max-width: 1080px;
    margin: 0 auto;

    @media ${device.laptop} {
      grid-gap: var(--sp-32);

      justify-content: center;
      grid-template-columns: auto auto;

      grid-gap: 100px;
    }

    .logo {
      .logo-link {
        display: inline-block;
      }
    }

    .navigation-list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: var(--sp-56);

      @media ${device.laptop} {
        /* grid-template-columns: repeat(5, 1fr); */
        grid-template-columns: repeat(5, auto);
        /* grid-gap: 100px; */
      }

      ul {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(auto-fit, 20px);
        grid-gap: var(--sp-16);

        .category {
          font-size: 1rem;
          font-weight: 500;
          margin-bottom: var(--8);

          @media ${device.laptop} {
            font-size: 14px;
          }
        }

        a {
          color: var(--primary-font-color);
          font-size: 14px;
          font-weight: normal;
          transition: 0.1s opacity ease-in-out;
          will-change: opacity;
          display: inline-block;

          &:hover {
            opacity: 0.5;
          }
          /* &.active {
            color: var(--water);
          } */
        }
      }
    }
  }
  .social-list {
    width: 100%;
    display: grid;
    margin-top: var(--sp-24);
    grid-gap: var(--sp-16);
    grid-template-columns: 1fr;

    li {
      transition: 0.1s opacity ease-in-out;
      will-change: opacity;

      a {
        display: inline-grid;

        grid-template-columns: 18px auto;
        align-items: center;
        grid-gap: var(--sp-12);

        &:hover {
          opacity: 0.5;
        }

        .socialIcon {
          width: 18px;
          height: 18px;
        }

        p {
          font-size: 0.875rem;
          line-height: 1;
        }
      }
    }
  }
`

export default DesktopFooter
