import { useReducer, useEffect, useState } from "react"
import PropTypes from "prop-types"

const Timer = (duration, max) => {
  const [interval, setInterval] = useState()

  const [count, dispatch] = useReducer((state, action) => {
    if (action.type === "next") {
      return state + 1 > max ? 0 : state + 1
    }

    if (action.type === "restart") {
      return 0
    }

    if (action.type === "reset") {
      return action.payload || 0
    }

    return state
  }, 0)

  const set = () => {
    const i = window.setInterval(() => {
      dispatch({ type: "next" })
    }, duration)

    setInterval(i)
  }

  const clear = () => {
    if (typeof window !== "undefined") {
      window.clearInterval(interval)
    }
  }

  const reset = (num) => {
    clear()
    set()
    dispatch({ type: "reset", payload: num })
  }

  const stop = (num) => {
    dispatch({ type: "reset", payload: num })
    clear()
  }

  useEffect(() => {
    return () => {
      clear()
    }
    // eslint-disable-next-line
  }, [])

  if (max === 0) {
    clear()
    return {
      count: 0,
      reset: () => {},
      clear: () => {},
      stop: () => {},
    }
  }

  return { count, reset, clear, stop, set }
}

Timer.propTypes = {
  isVisible: true,
  duration: PropTypes.number.isRequired,
}

export default Timer
