import PropTypes from "prop-types"
import { useCallback } from "react"

/**
 * useTracking
 * @param {object} props
 * @param {('select_content' | 'generate_lead' | 'view_item')} props.action
 * @param {string} props.category
 * @param {string} props.label
 * @param {number} props.value
 * @param {Boolean} props.non_interaction
 *
 * @example
 * const [track] = useTracking({})
 */
const useTracking = (props) => {
  const { category, action, label, value } = props ? props : {}

  const track = useCallback(
    (data) => {
      if (typeof window !== "undefined" && window.gtag) {
        window.gtag("event", action, {
          event_category: category,
          event_label: label,
          ...data,
        })
      }

      if (typeof window !== "undefined" && window.analytics) {
        window.analytics.track(action, {
          category,
          label,
          value,
          data,
        })
      }
    },
    [category, action, label, value]
  )

  return [track]
}
// https://developers.google.com/analytics/devguides/collection/gtagjs/events#default_google_analytics_events
useTracking.defaultProps = {
  action: "select_content", // string - required - Type of interaction (e.g. 'play')
  category: "", // string - optional - The object that was interacted with (e.g.video)
  label: "", // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
  value: "", // number - optional - Numeric value associated with the event. (e.g. A product ID),
  non_interaction: false, // To send a non-interaction event, set the non_interaction parameter to true:
}

// non_interaction event...
// gtag('event', 'video_auto_play_start', {
//     'event_label': 'My promotional video',
//     'event_category': 'video_auto_play',
//     'non_interaction': true
//   });

useTracking.propTypes = {
  action: PropTypes.oneOf(["select_content", "generate_lead", "view_item"])
    .required,
  label: PropTypes.string.required,
  category: PropTypes.string,
  value: PropTypes.number,
  non_interaction: PropTypes.bool,
}

export default useTracking
