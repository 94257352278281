import { css } from "styled-components"

export default css`
  label {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 16px;
    margin-bottom: var(--sp-8);
    display: inline-block;
  }

  input {
    font-size: 1rem;
    border: 1px solid #c6bfb9;
    width: 100%;
    padding: var(--sp-16);
    /* height: 50px; */
    border-radius: 8px;
    font-family: var(--primary-font-family);
    font-weight: normal;
    /* remove style autocomplete */
    box-shadow: 0 0 0px 1000px var(--bg-background-color) inset;
    &:focus {
      outline: none;
      border: 1px solid var(--water);
    }

    &::placeholder {
      color: rgba(82, 74, 68, 0.48);
    }
  }
`
