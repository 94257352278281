import React, { useCallback, useContext, useLayoutEffect } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import "src/styles/reset.css"
import "src/styles/fonts/font-family.css"
import "src/styles/theme.css"
import "src/styles/layout.css"
import "src/styles/typography.css"

// import Nav from "./Nav"
import Footer from "./Footer"

import ErrorBoundary from "./ErrorBoundary"

// import CTABadge from "components/navigation/CTABadge"

import { useSetVh } from "utils"

import { Context as GreenhouseContext } from "src/utils/context/greenhouse"

const Layout = ({ children, hideFooter }) => {
  useSetVh()

  const {
    state: { jobs },
    getJobPosts,
  } = useContext(GreenhouseContext)

  const _getJobPosts = useCallback(() => {
    getJobPosts()
  }, [getJobPosts])
  useLayoutEffect(() => {
    if (!jobs) {
      _getJobPosts()
    }
  }, [_getJobPosts, jobs])

  return (
    <Container>
      {/* <CTABadge /> */}
      <ErrorBoundary>
        <main>{children}</main>
        {/* <div className="grid-lines">
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
        </div> */}
      </ErrorBoundary>
      {!hideFooter && <Footer />}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  flex: 1;
  background: var(--bg-background-color);
  /* position: relative; */

  main {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .grid-lines {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: var(--sp-16);
    z-index: 1;
    span {
      border-left: 1px solid #eee9e4;
    }
  }
`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
