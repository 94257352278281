import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import classNames from "classnames"

import { TextGroup, Media, Link, Cta } from "components/common"

import { glassStyle, animatedUnderline } from "utils"

const Card = ({
  href,
  to,
  hat,
  hatAs,
  title,
  titleAs,
  subtitle,
  subtitleAs,
  media,
  target,
  className,
  background,
  ctas,
  ctasTheme,
  custom,
  as,
}) => {
  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  }

  const renderCta = () => <Cta ctas={ctas} theme={ctasTheme} />

  const renderCardContent = () => (
    <>
      {media && <Media className="cardImage" media={media} />}
      {custom && custom()}
      <TextGroup
        className="description"
        hat={hat}
        hatAs={hatAs}
        title={title}
        titleAs={titleAs}
        subtitle={subtitle}
        subtitleAs={subtitleAs}
        cta={ctas && renderCta}
        theme={ctasTheme}
        underline
      />
    </>
  )

  return (
    <StyledCard
      as={as}
      background={background}
      variants={item}
      className={classNames(className, {
        background,
      })}
    >
      {to || href ? (
        <Link className="cardLink" to={to} href={href} target={target}>
          {renderCardContent()}
        </Link>
      ) : (
        renderCardContent()
      )}
    </StyledCard>
  )
}

const StyledCard = styled(motion.li)`
  --tg-grid-gap: var(--sp-8);
  transition: all 0.2s ease-in-out;
  transition-property: box-shadow;
  will-change: box-shadow;

  .cardLink {
    cursor: pointer;
    display: block;
  }

  .cardImage {
    margin-bottom: var(--sp-32);
    max-height: 220px;
    transition: all 0.2s ease-in-out;
    transition-property: transform, box-shadow;
    will-change: transform, box-shadow;
    box-shadow: var(--card-shadow);
    border-radius: var(--card-radius);

    img {
      border-radius: var(--card-radius);
    }
  }

  .title {
    text-decoration: underline 0.125rem rgba(0, 0, 0, 0);
    transition: text-decoration-color 300ms;

    ${animatedUnderline};
  }

  &:hover,
  &:focus {
    .cardImage {
      box-shadow: var(--card-shadow-hover);
    }

    .title {
      .animated_underline {
        background-size: 100% 2px;
      }
    }
  }

  .cta {
    margin-top: var(--sp-32);
  }

  &.background {
    ${glassStyle};
    padding: var(--sp-32);

    &:hover {
      box-shadow: var(--card-shadow-hover);
    }

    .cardImage {
      box-shadow: none;
      transform: none;
      border-radius: 0;
    }

    .title {
    }
  }
`

Card.defaultProps = {
  background: false,
  hatAs: "p",
  titleAs: "h5",
  subtitleAs: "pSmall",
}
export default Card
