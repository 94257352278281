import { useMemo } from "react"
import keyBy from "lodash/keyBy"

/**
 * @description Contentful Section Hook
 *
 * @param {Array<{sectionId: String}>} sections - contentful sections with sectionId
 * @returns [] - array keyed by sectionId
 */

 const useContentfulSection = sections =>
  useMemo(() => keyBy(sections, section => section.sectionId), [sections])

  export default useContentfulSection