import React from "react"
import styled from "styled-components"

import { AnimatePresence, motion } from "framer-motion"

import { useHover } from "src/utils"

// 1. hover link > open dropdown
// 2. if leave link without going to dropdown close
// 3. hover dropdown
// 4. leave dropdown > close dropdown
// 4. leave dropdown and hover link > open?

const Dropdown = ({ className, trigger: Trigger, children }) => {
  const [hoverDropdownRef, dropdownIsHovered] = useHover()

  const animate = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    // transition: {
    //   duration: 0.2,
    // },
  }

  return (
    <DropdownWrap className={className}>
      <Trigger className="trigger" />
      <DropdownContainer className="container" ref={hoverDropdownRef}>
        <AnimatePresence>
          {/* <StyledDropdown>{children}</StyledDropdown> */}
          {dropdownIsHovered && (
            <StyledDropdown {...animate}>{children}</StyledDropdown>
          )}
        </AnimatePresence>
      </DropdownContainer>
    </DropdownWrap>
  )
}

const DropdownWrap = styled.div`
  position: relative;
  display: inline-flex;
  z-index: 3;
  .trigger {
    display: inline-block;
  }
`

const DropdownContainer = styled.div`
  position: absolute;
  top: 0;
  min-height: 100%;
  left: 0px;
  min-width: 100%;
  z-index: 2;
`

const StyledDropdown = styled(motion.div)`
  margin-top: var(--dropdown-y);
  transform: translateX(var(--dropdown-x));
  z-index: 3;

  width: var(--dropdown-width);
  padding: var(--sp-16);
  border-radius: 8px;
  box-shadow: var(--dropdown-shadow);
  border: var(--dropdown-border);
  background: var(--dropdown-background);
`

Dropdown.defaultProps = {
  className: "dropdown",
  trigger: () => <button>hover me</button>,
  open: false,
}

export default Dropdown
