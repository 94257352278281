import React from "react"

import Video from "./Video"
import Image from "./Image"

const MissingImage = () => <span style={{ color: "red" }}>MISSING MEDIA</span>

const Media = ({ media, className, critical, alt }) => {
  if (!media) return <MissingImage />

  const getFileOrImage = ({ desktop, mobile, retina, youtubeUrl }) => {
    const { file, gatsbyImageData } = desktop

    if (gatsbyImageData) {
      return (
        <Image
          gatsby={{
            desktop,
            mobile,
            retina,
          }}
          width={media.mediaWidth}
          className={className}
          critical={critical}
          alt={alt}
        />
      )
    }

    // None Gatsby Image, SVG,Vido etc...
    if (file) {
      if (file.contentType.includes("video")) {
        //   mobile video??
        return <Video desktop={file.url} mobile={mobile.file.url} />
      }

      return (
        <Image
          width={media.mediaWidth}
          className={className}
          critical={critical}
          alt={alt}
          image={{
            desktop,
            mobile,
          }}
        />
      )
    }

    return <MissingImage />
  }

  if (media.fullWidthDesktop) {
    return getFileOrImage({
      desktop: media.fullWidthDesktop,
      mobile: media.fullWidthMobile,
    })
  }

  if (media.desktop) {
    return getFileOrImage({
      desktop: media.desktop,
      mobile: media.mobile,
      retina: media.retinaDesktop,
    })
  }

  if (media.youtubeUrl) {
    return <Video url={media.youtubeUrl} />
  }

  // video
  // gif
  // svg
  // inline
  // GatsbyImage

  return <MissingImage />
}

export default Media
