import { css } from "styled-components"

export default css`
  position: relative;
  left: 50%;
  margin-left: calc(var(--vw, 1vw) * -50);
  /* transform: translateX(-50%); */
  width: calc(var(--vw, 1vw) * 100);
  /* margin-right: 50%; */

  /* old... */
  /* right: 50%;
  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw; */
  /* width: calc(100vw - 8px); */
  /* align-self: auto;
  overflow: hidden; */
`
