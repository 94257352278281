import React, { useEffect, useState } from "react"
import ReactPlayer from "react-player"
import styled from "styled-components"
import { useInView, device } from "utils"

const Video = ({ desktop, mobile, hevc, muted, autoPlay, loop, url }) => {
  const { ref, isVisible } = useInView()

  const [playing, setPlaying] = useState(false)

  const isHevc =
    typeof window !== "undefined" &&
    navigator.userAgent.indexOf("Safari") !== -1 &&
    navigator.userAgent.indexOf("Chrome") === -1

  useEffect(() => {
    if (isVisible) {
      setPlaying(true)
    } else {
      setPlaying(false)
    }
  }, [isVisible])

  if (url) {
    return (
      <IframeWrap>
        <iframe
          width="560"
          height="315"
          // src="https://www.youtube.com/embed/XzaXPm-f98A?rel=0"

          src={url + "?rel=0"} // rel hides related videos on pause??
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </IframeWrap>
    )
  }

  return (
    <VideoWrap ref={ref} className="videoWrap">
      {!isHevc && (
        <>
          <ReactPlayer
            className="video desktop"
            width="100%"
            height="100%"
            playing={playing}
            url={desktop}
            muted={muted}
            autoPlay={autoPlay}
            loop={loop}
          />
          <ReactPlayer
            className="video mobile"
            width="100%"
            height="100%"
            playing={playing}
            url={mobile || desktop}
            muted={muted}
            autoPlay={autoPlay}
            loop={loop}
          />
        </>
      )}
      {isHevc && (
        <ReactPlayer
          className="video hevc"
          width="100%"
          height="100%"
          playing={playing}
          url={hevc}
          muted={muted}
          autoPlay={autoPlay}
          loop={loop}
        />
      )}
    </VideoWrap>
  )
}

const IframeWrap = styled.div`
  position: relative;
  /* - 16:9 aspect ratio (most common) */
  padding-bottom: 56.25%;

  /* 16:10 aspect ratio */
  /* padding-bottom: 62.5%; */

  /* - 4:3 aspect ratio */
  /* padding-bottom: 75%; */

  padding-top: 30px;
  height: 0;
  overflow: hidden;
  border-radius: var(--card-radius);

  iframe,
  object,
  embed {
    border: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: var(--card-radius);
  }
`

const VideoWrap = styled.div`
  height: 100%;
  width: 100%;

  .desktop {
    display: none;
  }
  @media ${device.laptop} {
    .mobile {
      display: none;
    }
    .desktop {
      display: block;
    }
  }

  .hevc {
  }
`

Video.defaultProps = {
  src: "",
  muted: true,
  autoPlay: false,
  loop: true,
}

export default Video
