import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { Section, TextGroup } from "components/common"

/**
 * @description to Edge horizational image section
 * @typedef {{
 *  hat: String
 *  title: String
 *  subtitle: String
 *  full: Boolean
 *  align: "left" | "center" | "right"
 *  image: {width: String, height: String, src: String, alt: String, fixed: {height: Number width: Number}, fluid: {sizes: String}}
 *  mobileImage: {width: String, height: String, src: String, alt: String, fixed: {height: Number width: Number}, fluid: {sizes: String}}
 *  cta: React.FunctionComponent
 * }}
 * Props
 * @type React.FunctionComponent<Props>
 */

const CenterSection = ({
  hat,
  title,
  subtitle,
  full,
  align,
  image,
  mobileImage,
  cta,
}) => {
  return (
    <StyledCenterSection>
      <Section image={image} mobileImage={mobileImage} full={full}>
        <TextSection align={align}>
          <TextGroup
            hat={hat}
            title={title}
            subtitle={subtitle}
            align={align}
            cta={cta}
          />
        </TextSection>
      </Section>
    </StyledCenterSection>
  )
}

const StyledCenterSection = styled.div``

const orienationLib = {
  left: "flex-start",
  center: "center",
  right: "flex-end",
}

const TextSection = styled.div`
  /* align-self: ${({ align }) => orienationLib[align]};
  text-align: ${({ align }) => align}; */
`

CenterSection.defaultProps = {
  hat: "",
  title: "",
  subtitle: "",
  full: false,
  align: "center",
  image: {},
  mobileImage: {},
  cta: null,
}

CenterSection.propTypes = {
  full: PropTypes.bool.isRequired,
  align: PropTypes.oneOf(["left", "right", "center"]).isRequired,
  hat: PropTypes.oneOfType([
    PropTypes.shape({
      raw: PropTypes.string.isRequired,
    }),
    PropTypes.string,
  ]),
  title: PropTypes.oneOfType([
    PropTypes.shape({
      raw: PropTypes.string.isRequired,
    }),
    PropTypes.string,
  ]),
  subtitle: PropTypes.oneOfType([
    PropTypes.shape({
      raw: PropTypes.string.isRequired,
    }),
    PropTypes.string,
  ]),
  image: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
    src: PropTypes.string,
    alt: PropTypes.string,
    fixed: PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
    }),
    file: PropTypes.shape({
      url: PropTypes.string.isRequired,
      fileName: PropTypes.string,
    }),
    fluid: PropTypes.shape({ sizes: PropTypes.string.isRequired }),
  }),
  mobileImage: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
    src: PropTypes.string,
    alt: PropTypes.string,
    fixed: PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
    }),
    file: PropTypes.shape({
      url: PropTypes.string.isRequired,
      fileName: PropTypes.string,
    }),
    fluid: PropTypes.shape({ sizes: PropTypes.string.isRequired }),
  }),
  cta: PropTypes.func,
}

export default CenterSection
