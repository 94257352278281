import { useStaticQuery, graphql } from "gatsby"

// import favicon from "src/images/favicon.png"

const useMeta = ({ metaTitle, metaDescription, metaImage, metaKeywords }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            description
            author
          }
        }
      }
    `
  )

  const title = metaTitle
  const keywords = metaKeywords ? metaKeywords.join(",") : ""
  const description = metaDescription || site.siteMetadata.description
  // const image = metaImage && metaImage.file ? metaImage.file.url : favicon
  const image = metaImage && metaImage.file ? metaImage.file.url : ""

  return {
    title,
    description,
    image,
    author: site.siteMetadata.author,
    keywords,
  }
}

export default useMeta
