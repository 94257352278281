import React from "react"
import styled from "styled-components"

import map from "lodash/map"

import { Accordion } from "components/common"

const FAQ = (faqs) => {
  const renderFAQ = map(faqs, (faq, idx) => (
    <li className="faqWrap" key={idx}>
      <Accordion title={faq.question} content={faq.answer} />
    </li>
  ))

  return <StyledFAQ>{renderFAQ}</StyledFAQ>
}

const StyledFAQ = styled.ul`
  --tg-title-color: var(--faq-color);
  --tg-subtitle-color: var(--faq-color);

  .faqWrap {
    border-top: 1px solid #3d4d7c;

    &:last-child {
      border-bottom: 1px solid #3d4d7c;
    }

    p {
      font-size: 1rem;
      line-height: 24px;
      font-weight: normal;
    }
  }
`

export default FAQ
