import { css } from "styled-components"

import { device } from "utils"

const richTextBody = () => css`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  ol,
  li,
  a {
    color: var(--primary-font-color);
  }

  h2,
  h3,
  h4 {
    margin-bottom: var(--sp-24);
  }

  h5 {
    color: var(--dirt);
    margin-bottom: var(--sp-8);
  }

  p {
    margin-bottom: var(--sp-16);
    white-space: break-spaces;
    color: var(--dirt);
    @media ${device.laptop} {
      margin-bottom: var(--sp-32);
    }
  }

  blockquote {
    @media ${device.laptop} {
      margin-bottom: var(--sp-32);
      margin-top: 0;
    }
    p {
      font-family: var(--title-font-family);
      color: #6270d2;
      max-width: 580px;

      text-align: left;
      /* 20px */
      font-size: 1.25rem;
      line-height: 32px;
    }
  }

  ul,
  ol {
    list-style: initial;
    padding-left: 30px;
    margin-bottom: var(--sp-32);

    display: grid;
    grid-template-columns: 1fr;
    grid-gap: var(--sp-16);

    @media ${device.laptop} {
      grid-gap: var(--sp-24);
    }

    p {
      margin-bottom: 0;
    }
  }

  ol {
    list-style: decimal;
  }

  ul {
    grid-gap: var(--sp-12);
    @media ${device.laptop} {
      grid-gap: var(--sp-12);
    }
  }

  img {
    width: 100%;
    border-radius: var(--card-radius);
  }

  a {
    font-weight: 500;
    color: var(--spruce);
  }

  i {
    font-style: italic;
  }
  strong {
    font-weight: 500;
  }
`

export default richTextBody
