import React, { useCallback } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import { TextGroup, Section, Media } from "components/common"
import { device, fullContent } from "utils"

/**
 * @description Vertical Section
 * @typedef {{
 *  nav: React.FunctionComponent
 *  showNav: boolean=false
 *  hat: {}
 *  hatAs: String
 *  title: {}
 *  titleAs: String
 *  subtitle: {}
 *  subtitleAs: String
 *  full: Boolean
 *  mobileFull: Boolean
 *  align: "left" | "center" | "right"
 *  imageWidth: string
 *  critical: boolean=false
 *  image: {ratio: 1 | 2 | 3, width: String, height: String, src: String, alt: String, fixed: {height: Number width: Number}, fluid: {sizes: String}}
 *  backgroundImage: {width: String, height: String, src: String, alt: String, fixed: {height: Number width: Number}, fluid: {sizes: String}}
 *  mobileImage: {width: String, height: String, src: String, alt: String, fixed: {height: Number width: Number}, fluid: {sizes: String}}
 *  mobileBackgroundImage: {width: String, height: String, src: String, alt: String, fixed: {height: Number width: Number}, fluid: {sizes: String}}
 *  cta: React.FunctionComponent
 *  grid: React.FunctionComponent
 *  custom: React.FunctionComponent
 *  divider: React.FunctionComponent
 *  tgOrder: "1" | "2" | "3" | "4" | "5"
 *  ctaOrder: "1" | "2" | "3" | "4" | "5"
 *  imageOrder: "1" | "2" | "3" | "4" | "5"
 *  gridOrder: "1" | "2" | "3" | "4" | "5"
 *  customOrder: "1" | "2" | "3" | "4" | "5"
 * mobileTgOrder: "1" | "2" | "3" | "4" | "5"
 * mobileCtaOrder: "1" | "2" | "3" | "4" | "5"
 * mobileImageOrder: "1" | "2" | "3" | "4" | "5"
 * mobileGridOrder: "1" | "2" | "3" | "4" | "5"
 * mobileCustomOrder: "1" | "2" | "3" | "4" | "5"
 * }}
 * Props
 * @type React.FunctionComponent<Props>
 */

const VerticalSection = ({
  nav,
  showNav,
  hatIcon,
  hat,
  hatAs,
  title,
  titleAs,
  subtitle,
  subtitleAs,
  full,
  mobileFull,
  align,
  // imageWidth,
  critical,
  media,
  backgroundImage,
  cta,
  grid,
  custom,
  divider,
  tgOrder,
  ctaOrder,
  imageOrder,
  gridOrder,
  customOrder,
  mobileTgOrder,
  mobileCtaOrder,
  mobileImageOrder,
  mobileGridOrder,
  mobileCustomOrder,
  ...other
}) => {
  const renderCtas = useCallback(() => cta(), [cta])
  const renderCustom = useCallback(() => custom(), [custom])
  const renderGrid = useCallback(() => grid(), [grid])
  const renderDivider = useCallback(() => divider(), [divider])

  return (
    <StyledVerticalSection align={align} {...other}>
      <Section
        backgroundImage={backgroundImage}
        full={full}
        critical={critical}
        showNav={showNav}
        nav={nav}
      >
        <TGWrap
          className="tgContainer"
          align={align}
          tgOrder={tgOrder}
          mobileTgOrder={mobileTgOrder}
        >
          <TextGroup
            align={align}
            hatIcon={hatIcon}
            hat={hat}
            hatAs={hatAs}
            title={title}
            titleAs={titleAs}
            subtitle={subtitle}
            subtitleAs={subtitleAs}
          />
        </TGWrap>

        {cta && (
          <CTAWrap
            className="ctaWrap"
            align={align}
            ctaOrder={ctaOrder}
            mobileCtaOrder={mobileCtaOrder}
          >
            {renderCtas()}
          </CTAWrap>
        )}

        {media && (
          <ImageWrap
            align={align}
            // height={image.height}
            imageOrder={imageOrder}
            mobileImageOrder={mobileImageOrder}
            className={`imageWrap ${full ? "full" : ""} ${
              mobileFull ? "mobileFull" : ""
            }`}
          >
            <Media media={media} critical={critical} />
          </ImageWrap>
        )}

        {grid && (
          <GridWrap
            className="gridWrap"
            gridOrder={gridOrder}
            mobileGridOrder={mobileGridOrder}
          >
            {renderGrid()}
          </GridWrap>
        )}

        {custom && (
          <CustomWrap
            className="customWrap"
            customOrder={customOrder}
            mobileCustomOrder={mobileCustomOrder}
          >
            {renderCustom()}
          </CustomWrap>
        )}
        {divider && <Divider className="divider">{renderDivider()}</Divider>}
      </Section>
    </StyledVerticalSection>
  )
}

const ALIGN_LIB = {
  left: "flex-start",
  center: "center",
  right: "flex-end",
}

const StyledVerticalSection = styled.div``

const TGWrap = styled.div`
  display: flex;
  justify-content: ${({ align }) => ALIGN_LIB[align]};

  order: ${({ mobileTgOrder }) => mobileTgOrder};

  margin-top: ${({ mobileTgOrder }) =>
    mobileTgOrder !== "1" && "var(--sp-32);"};
  @media ${device.laptop} {
    margin-top: ${({ tgOrder }) => (tgOrder === "1" ? 0 : "var(--sp-32)")};
    order: ${({ tgOrder }) => tgOrder};
  }
`

const ImageWrap = styled.div`
  /* width: 100%; */
  display: flex;
  justify-content: ${({ align }) => ALIGN_LIB[align]};
  order: ${({ mobileImageOrder }) => mobileImageOrder};
  margin: 0 auto var(--sp-16);
  margin: ${({ mobileImageOrder }) =>
    mobileImageOrder === "1" ? "0 auto var(--sp-32)" : "var(--sp-32) auto 0"};

  @media ${device.laptop} {
    order: ${({ imageOrder }) => imageOrder};
    margin: ${({ imageOrder }) =>
      imageOrder === "1" ? "0 auto var(--sp-56)" : "var(--sp-56) auto 0"};
  }

  &.mobileFull {
    position: relative;
    display: block;
    margin: ${({ mobileImageOrder }) =>
      mobileImageOrder === "1" ? 0 : "var(--sp-32) 0 0"};

    left: calc(var(--section-mobile-padding) * -1);
    margin-right: calc(var(--section-mobile-padding) * -2);

    img {
      width: 100%;
    }

    @media ${device.tablet} {
      left: calc(var(--sp-64) * -1);
      margin-right: calc(var(--sp-64) * -2);
    }

    @media ${device.laptop} {
      left: initial;
      margin-right: initial;
      margin: ${({ imageOrder }) =>
        imageOrder === "1" ? 0 : "var(--sp-56) auto 0;"};
      img {
        width: initial;
      }
    }
  }

  &.full {
    @media ${device.laptop} {
      ${fullContent};
      /* display: block;
      position: relative;
      margin: ${({ imageOrder }) =>
        imageOrder === "1" ? 0 : "var(--sp-56) auto 0;"};

      left: calc(var(--sp-64) * -1);
      margin-right: calc(var(--sp-64) * -2);
      img {
        width: 100%;
      } */
    }
  }
`

const CTAWrap = styled.div`
  margin-top: ${({ mobileCtaOrder }) =>
    mobileCtaOrder !== "1" && "var(--sp-32);"};

  align-self: ${({ align }) => ALIGN_LIB[align]};

  order: ${({ mobileCtaOrder }) => mobileCtaOrder};
  @media ${device.laptop} {
    margin-top: ${({ ctaOrder }) => (ctaOrder === "1" ? 0 : "var(--sp-32)")};
    order: ${({ ctaOrder }) => ctaOrder};
  }
`

const GridWrap = styled.div`
  margin-top: ${({ mobileGridOrder }) =>
    mobileGridOrder !== "1" && "var(--sp-32);"};
  order: ${({ mobileGridOrder }) => mobileGridOrder};
  @media ${device.laptop} {
    order: ${({ gridOrder }) => gridOrder};
    margin-top: ${({ gridOrder }) => (gridOrder === "1" ? 0 : "var(--sp-64)")};
  }
`

const CustomWrap = styled.div`
  margin-top: ${({ mobileCustomOrder }) =>
    mobileCustomOrder !== "1" && "var(--sp-32);"};
  order: ${({ mobileCustomOrder }) => mobileCustomOrder};
  @media ${device.laptop} {
    margin-top: ${({ customOrder }) =>
      customOrder === "1" ? 0 : "var(--sp-32)"};
    order: ${({ customOrder }) => customOrder};
  }
`

const Divider = styled.div`
  order: 99;
`

VerticalSection.defaultProps = {
  hat: "",
  hatAs: "h6",
  title: "",
  titleAs: "h2",
  subtitle: "",
  subtitleAs: "p",
  full: false,
  critical: false,

  mobileFull: false,
  align: "center",

  image: null,
  cta: null,
  grid: null,
  tgOrder: "1",
  ctaOrder: "2",
  imageOrder: "3",
  gridOrder: "4",
  customOrder: "4",
  mobileImageOrder: "3",
  mobileTgOrder: "1",
  mobileCtaOrder: "2",
  mobileGridOrder: "4",
  mobileCustomOrder: "4",
}

VerticalSection.propTypes = {
  hat: PropTypes.oneOfType([
    PropTypes.shape({
      raw: PropTypes.string.isRequired,
    }),
    PropTypes.string,
  ]),

  title: PropTypes.oneOfType([
    PropTypes.shape({
      raw: PropTypes.string.isRequired,
    }),
    PropTypes.string,
  ]),

  subtitle: PropTypes.oneOfType([
    PropTypes.shape({
      raw: PropTypes.string.isRequired,
    }),
    PropTypes.string,
  ]),
  hasAs: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6", "p"]),
  titleAs: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6", "p"]),
  subtitleAs: PropTypes.oneOf([
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "p",
    "pSmall",
  ]),
  full: PropTypes.bool,
  critical: PropTypes.bool,
  mobileFull: PropTypes.bool,
  align: PropTypes.oneOf(["left", "right", "center"]),
  // imageWidth: PropTypes.string,
  image: PropTypes.shape({
    height: PropTypes.string,
    width: PropTypes.string,
    ratio: PropTypes.oneOf([1, 2, 3]),
    fixed: PropTypes.shape({
      height: PropTypes.number.isRequired,
      width: PropTypes.number.isRequired,
    }),
    fluid: PropTypes.shape({
      sizes: PropTypes.string.isRequired,
    }),
    file: PropTypes.shape({
      url: PropTypes.string.isRequired,
      fileName: PropTypes.string,
      details: PropTypes.shape({
        image: PropTypes.shape({
          width: PropTypes.number,
        }),
      }),
    }),
  }),
  backgroundImage: PropTypes.shape({
    height: PropTypes.string,
    width: PropTypes.string,
    fixed: PropTypes.shape({
      height: PropTypes.number.isRequired,
      width: PropTypes.number.isRequired,
    }),
    fluid: PropTypes.shape({
      sizes: PropTypes.string.isRequired,
    }),
  }),
  mobileImage: PropTypes.shape({
    height: PropTypes.string,
    width: PropTypes.string,
    fixed: PropTypes.shape({
      height: PropTypes.number.isRequired,
      width: PropTypes.number.isRequired,
    }),
    fluid: PropTypes.shape({
      sizes: PropTypes.string.isRequired,
    }),
  }),
  mobileBackgroundImage: PropTypes.shape({
    height: PropTypes.string,
    width: PropTypes.string,
    fixed: PropTypes.shape({
      height: PropTypes.number.isRequired,
      width: PropTypes.number.isRequired,
    }),
    fluid: PropTypes.shape({
      sizes: PropTypes.string.isRequired,
    }),
  }),
  cta: PropTypes.func,

  grid: PropTypes.func,

  custom: PropTypes.func,

  tgOrder: PropTypes.oneOf(["1", "2", "3", "4", "5"]).isRequired,
  ctaOrder: PropTypes.oneOf(["1", "2", "3", "4", "5"]).isRequired,
  imageOrder: PropTypes.oneOf(["1", "2", "3", "4", "5"]).isRequired,
  gridOrder: PropTypes.oneOf(["1", "2", "3", "4", "5"]).isRequired,
  customOrder: PropTypes.oneOf(["1", "2", "3", "4", "5"]).isRequired,

  mobileTgOrder: PropTypes.oneOf(["1", "2", "3", "4", "5"]).isRequired,
  mobileCtaOrder: PropTypes.oneOf(["1", "2", "3", "4", "5"]).isRequired,
  mobileImageOrder: PropTypes.oneOf(["1", "2", "3", "4", "5"]).isRequired,
  mobileGridOrder: PropTypes.oneOf(["1", "2", "3", "4", "5"]).isRequired,
  mobileCustomOrder: PropTypes.oneOf(["1", "2", "3", "4", "5"]).isRequired,
}

export default VerticalSection
