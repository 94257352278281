import React, { useCallback } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { Section, TextGroup, Media } from "components/common"

import { device, fullContent } from "utils"

/**
 * @description Horizational Template Section
 * 
* @typedef {{
 *  critical: boolean
 *  full: boolean
 *  mobileFull: boolean
 *  right: boolean
 *  align: "left" | "center" | "right"
 *  full: boolean=false
 *  hasAs: string
 *  hat: string
 *  titleAs: String
 *  title: string
 *  subtitleAs: string
 *  subtitle: string
 *  image: {ratio: 1 | 2 | 3, width: String, height: String, src: String, alt: String, fixed: {height: Number width: Number}, fluid: {sizes: String}}
 *  mobileImage: {width: String, height: String, src: String, alt: String, fixed: {height: Number width: Number}, fluid: {sizes: String}}
 *  backgroundImage: {width: String, height: String, src: String, alt: String, fixed: {height: Number width: Number}, fluid: {sizes: String}}
 *  cta: React.FunctionComponent
 *  custom: React.FunctionComponent
 *  divider: React.FunctionComponent
 *  grid: React.FunctionComponent
 *  className: String
 *  mobileImageOrder: "1" | "2"
 *  mobileTextOrder: "1" | "2"
 *  tgOrder: "1" | "2" | "3" | "4" 
 *  ctaOrder: "1" | "2" | "3" | "4" 
 *  gridOrder: "1" | "2" | "3" | "4" 
 *  customOrder: "1" | "2" | "3" | "4" 
 *  mobileTgOrder: "1" | "2" | "3" | "4" | "5"
 *  mobileCtaOrder: "1" | "2" | "3" | "4" | "5"
 *  mobileGridOrder: "1" | "2" | "3" | "4" | "5"
 *  mobileCustomOrder: "1" | "2" | "3" | "4" | "5"
 * }}
 * 
 * Props
 * @type React.FunctionComponent<Props>
 
 */

const HorizontalSection = ({
  right,
  align,
  full,
  mobileFull,
  hatIcon,
  hat,
  title,
  subtitle,
  cta,
  media,
  backgroundImage,
  grid,
  hatAs,
  titleAs,
  subtitleAs,
  className,
  custom,
  divider,
  tgOrder,
  ctaOrder,
  gridOrder,
  customOrder,
  mobileTgOrder,
  mobileCtaOrder,
  mobileImageOrder,
  mobileTextOrder,
  mobileGridOrder,
  mobileCustomOrder,
  customMedia,
  critical,
}) => {
  const renderGrid = useCallback(() => grid(), [grid])
  const renderCtas = useCallback(() => cta(), [cta])
  const renderCustom = useCallback(() => custom(), [custom])
  const renderDivider = useCallback(() => divider(), [divider])

  return (
    <StyledHorizontalSection className={className} full={full}>
      <Section backgroundImage={backgroundImage} critical={critical}>
        <HalfSideContainer right={right} className="half-side-container">
          <TextSection
            className="horizontal_textSection"
            right={right}
            mobileTextOrder={mobileTextOrder}
          >
            <TGWrap
              tgOrder={tgOrder}
              mobileTgOrder={mobileTgOrder}
              className="tgWrap"
            >
              <TextGroup
                hatIcon={hatIcon}
                hat={hat}
                title={title}
                subtitle={subtitle}
                align={align}
                hatAs={hatAs}
                titleAs={titleAs}
                subtitleAs={subtitleAs}
              />
            </TGWrap>
            {cta && (
              <CTAWrap
                align={align}
                className="ctaWrap"
                ctaOrder={ctaOrder}
                mobileCtaOrder={mobileCtaOrder}
              >
                {renderCtas()}
              </CTAWrap>
            )}
            {grid && (
              <GridWrap
                className="gridWrap"
                gridOrder={gridOrder}
                mobileGridOrder={mobileGridOrder}
              >
                {renderGrid()}
              </GridWrap>
            )}
            {custom && (
              <CustomWrap
                className="customWrap"
                customOrder={customOrder}
                mobileCustomOrder={mobileCustomOrder}
              >
                {renderCustom()}
              </CustomWrap>
            )}
          </TextSection>
          {media && (
            <ImageSection
              right={right}
              mobileImageOrder={mobileImageOrder}
              className={`horizontalWrap_image ${full ? "full" : ""} ${
                mobileFull ? "mobileFull" : ""
              }`}
            >
              <Media media={media} critical={critical} />
              {customMedia && customMedia()}
            </ImageSection>
          )}
        </HalfSideContainer>

        {divider && <Divider className="divider">{renderDivider()}</Divider>}
      </Section>
    </StyledHorizontalSection>
  )
}

const StyledHorizontalSection = styled.div`
  --section-overflow: ${({ mobileFull }) => (mobileFull ? "hidden" : "auto")};
  @media ${device.laptop} {
    --section-overflow: ${({ full }) => (full ? "hidden" : "auto")};
  }
`

const HalfSideContainer = styled.div`
  display: grid;

  align-items: center;
  justify-content: center;

  grid-template-columns: 1fr;
  grid-gap: var(--sp-32);

  /* 1024 */
  @media ${device.laptop} {
    grid-gap: var(--sp-32);
    grid-template-columns: repeat(auto-fit, minmax(472px, auto));
  }

  @media ${device.desktop} {
    grid-gap: var(--sp-64);
    grid-template-columns: repeat(auto-fit, minmax(480px, auto));
  }
`

const ImageSection = styled.div`
  order: ${({ mobileImageOrder }) => mobileImageOrder};
  @media ${device.laptop} {
    order: ${({ right }) => (right ? 1 : 2)};
  }

  .horizontal_image {
    @media ${device.laptop} {
      margin: ${({ right }) => (right ? "0 0 0 auto" : "0 auto 0 0")};
    }
  }

  &.mobileFull {
    ${fullContent};

    img {
      width: 100%;
    }
    @media ${device.laptop} {
      width: initial;
      left: initial;
      margin-left: 0;

      img {
        width: auto;
      }
      /* transform: translateX(0); */
    }
  }

  &.full {
    @media ${device.laptop} {
      justify-self: ${({ right }) => (right ? "flex-end" : "flex-start")};
      left: initial;
      width: calc(var(--vw) * 50);
      margin-left: 0;

      img {
        width: 100%;
      }
      /* transform: translateX(0); */
    }
  }
`

const TextSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content;

  order: ${({ mobileTextOrder }) => mobileTextOrder};
  @media ${device.laptop} {
    order: ${({ right }) => (right ? 2 : 1)};
  }
`

const TGWrap = styled.div`
  order: ${({ mobileTgOrder }) => mobileTgOrder};

  margin-top: ${({ mobileTgOrder }) =>
    mobileTgOrder === "1" ? 0 : "var(--sp-32)"};

  @media ${device.laptop} {
    margin-bottom: 0;
    margin-top: ${({ tgOrder }) => (tgOrder === "1" ? 0 : "var(--sp-32)")};
    order: ${({ tgOrder }) => tgOrder};
  }
`

const CTAWrap = styled.div`
  margin-top: ${({ mobileCtaOrder }) =>
    mobileCtaOrder !== "1" && "var(--sp-32)"};
  text-align: ${({ align }) => align};
  order: ${({ mobileCtaOrder }) => mobileCtaOrder};
  @media ${device.laptop} {
    margin-top: ${({ ctaOrder }) => (ctaOrder === "1" ? 0 : "var(--sp-32)")};
    order: ${({ ctaOrder }) => ctaOrder};
  }
`

const GridWrap = styled.div`
  margin-top: ${({ mobileGridOrder }) =>
    mobileGridOrder !== "1" && "var(--sp-32)"};
  order: ${({ mobileGridOrder }) => mobileGridOrder};
  @media ${device.laptop} {
    order: ${({ gridOrder }) => gridOrder};
    margin-top: ${({ gridOrder }) => (gridOrder === "1" ? 0 : "var(--sp-64)")};
  }
`

const CustomWrap = styled.div`
  margin-top: ${({ mobileCustomOrder }) =>
    mobileCustomOrder !== "1" && "var(--sp-32)"};

  order: ${({ mobileCustomOrder }) => mobileCustomOrder};
  @media ${device.laptop} {
    margin-top: ${({ customOrder }) =>
      customOrder === "1" ? 0 : "var(--sp-32)"};
    order: ${({ customOrder }) => customOrder};
  }
`

const Divider = styled.div`
  order: 99;
`

HorizontalSection.defaultProps = {
  right: false,
  critical: false,
  align: "left",
  full: false,
  mobileFull: false,
  hat: "",
  title: "",
  subtitle: "",
  height: null,
  image: null,
  mobileImage: null,
  cta: null,
  grid: null,
  hatAs: "h6",
  titleAs: "h2",
  subtitleAs: "pSmall",
  tgOrder: "1",
  ctaOrder: "2",
  gridOrder: "3",
  customOrder: "3",
  mobileTextOrder: "2",
  mobileImageOrder: "1",
  mobileTgOrder: "2",
  mobileGridOrder: "3",
  mobileCustomOrder: "3",
  mobileCtaOrder: "4",
}

HorizontalSection.propTypes = {
  right: PropTypes.bool,
  critical: PropTypes.bool,
  full: PropTypes.bool,
  align: PropTypes.oneOf(["left", "right", "center"]),
  hat: PropTypes.oneOfType([
    PropTypes.shape({
      raw: PropTypes.string.isRequired,
    }),
    PropTypes.string,
  ]),
  subtitle: PropTypes.oneOfType([
    PropTypes.shape({
      raw: PropTypes.string.isRequired,
    }),
    PropTypes.string,
  ]),
  title: PropTypes.oneOfType([
    PropTypes.shape({
      raw: PropTypes.string.isRequired,
    }),
    PropTypes.string,
  ]),
  hasAs: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6", "p"]),
  titleAs: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6", "p"]),
  subtitleAs: PropTypes.oneOf([
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "p",
    "pSmall",
  ]),
  height: PropTypes.string,
  // imageWidth: PropTypes.string,
  image: PropTypes.shape({
    ratio: PropTypes.number,
    width: PropTypes.string,
    height: PropTypes.string,
    src: PropTypes.string,
    alt: PropTypes.string,
    fixed: PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
    }),
    file: PropTypes.shape({
      url: PropTypes.string.isRequired,
      fileName: PropTypes.string,
      details: PropTypes.shape({
        image: PropTypes.shape({
          width: PropTypes.number,
        }),
      }),
    }),
    fluid: PropTypes.shape({ sizes: PropTypes.string.isRequired }),
  }),
  mobileImage: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
    src: PropTypes.string,
    alt: PropTypes.string,
    fixed: PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
    }),
    file: PropTypes.shape({
      url: PropTypes.string.isRequired,
      fileName: PropTypes.string,
    }),
    fluid: PropTypes.shape({ sizes: PropTypes.string.isRequired }),
  }),
  backgroundImage: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
    src: PropTypes.string,
    alt: PropTypes.string,
    fixed: PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
    }),
    file: PropTypes.shape({
      url: PropTypes.string.isRequired,
      fileName: PropTypes.string,
    }),
    fluid: PropTypes.shape({ sizes: PropTypes.string.isRequired }),
  }),
  cta: PropTypes.func,
  custom: PropTypes.func,
  grid: PropTypes.func,
  mobileImageOrder: PropTypes.oneOf(["1", "2", "3", "4"]).isRequired,
  mobileTextOrder: PropTypes.oneOf(["1", "2"]).isRequired,
  tgOrder: PropTypes.oneOf(["1", "2", "3", "4"]).isRequired,
  ctaOrder: PropTypes.oneOf(["1", "2", "3", "4"]).isRequired,
  gridOrder: PropTypes.oneOf(["1", "2", "3", "4"]).isRequired,
  customOrder: PropTypes.oneOf(["1", "2", "3", "4"]).isRequired,
  mobileTgOrder: PropTypes.oneOf(["1", "2", "3", "4", "5"]).isRequired,
  mobileCtaOrder: PropTypes.oneOf(["1", "2", "3", "4", "5"]).isRequired,
  mobileGridOrder: PropTypes.oneOf(["1", "2", "3", "4", "5"]).isRequired,
  mobileCustomOrder: PropTypes.oneOf(["1", "2", "3", "4", "5"]).isRequired,
}

export default HorizontalSection
