import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import classNames from "classnames"

import { TextGroup, Media, Link, Cta } from "components/common"

import { glassStyle, animatedUnderline, device } from "utils"

const CardLinkOutside = ({
  href,
  to,
  hat,
  hatAs,
  title,
  titleAs,
  subtitle,
  subtitleAs,
  media,
  target,
  className,
  background,
  ctas,
  ctasTheme,
  custom,
  align,
  icon,
}) => {
  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  }

  const renderCta = () => <Cta ctas={ctas} theme={ctasTheme} />

  const renderCardContent = () => (
    <>
      <TextGroup
        className="description"
        align={align}
        textAlign={align}
        hatIcon={{ desktop: icon }}
        hat={hat}
        hatAs={hatAs}
        title={title}
        titleAs={titleAs}
        subtitle={subtitle}
        subtitleAs={subtitleAs}
        underline
      />
      {media && <Media className="cardImage" media={media} />}
      {custom && custom()}
    </>
  )

  return (
    <StyledCard
      background={background}
      variants={item}
      className={classNames(className, {
        background,
      })}
    >
      {to || href ? (
        <Link className="cardLink card" to={to} href={href} target={target}>
          {renderCardContent()}
        </Link>
      ) : (
        <div className="card">renderCardContent()</div>
      )}
      <div className="ctaWrap">{renderCta()}</div>
    </StyledCard>
  )
}

const StyledCard = styled(motion.li)`
  --tg-grid-gap: var(--sp-8);

  .card {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    ${glassStyle};
    padding: var(--sp-32);
    transition: all 0.2s ease-in-out;
    transition-property: box-shadow;
    will-change: box-shadow;

    &.cardLink {
      cursor: pointer;
    }

    .hatIconWrap {
      margin: 0 auto;
    }
    .title {
      text-decoration: underline 0.125rem rgba(0, 0, 0, 0);
      transition: text-decoration-color 300ms;

      ${animatedUnderline};
    }

    &:hover,
    &:focus {
      box-shadow: var(--card-shadow-hover);

      .title {
        .animated_underline {
          background-size: 100% 2px;
        }
      }
    }

    .cardImage {
      margin: 0 auto;
    }
  }

  .ctaWrap {
    margin: 0 auto;
    text-align: center;
    margin-top: var(--sp-32);

    @media ${device.laptop} {
      margin-top: var(--sp-48);
    }
  }
`

CardLinkOutside.defaultProps = {
  background: false,
  hatAs: "p",
  titleAs: "h5",
  subtitleAs: "pSmall",
  align: "center",
}
export default CardLinkOutside
