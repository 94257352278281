import { css } from "styled-components"

import { device } from "utils"

export default css`
  /* background: #02132b; */
  border: var(--backbone-border);
  box-shadow: var(--card-shadow);
  border-radius: 16px;

  @media ${device.laptop} {
    border-radius: var(--card-radius);
  }
`
