import React, { useEffect, useState } from "react"
import styled from "styled-components"
import map from "lodash/map"

import { motion } from "framer-motion"

import { useCount, device } from "utils"
import { Media } from "."
import classNames from "classnames"

const Testimonials = ({ testimonials, isVisible }) => {
  const [animationActive, setAnimationActive] = useState(false)

  const dots = Array(testimonials.length)
    .fill(null)
    .map((_, i) => i)

  const showBar = testimonials.length > 1

  const { count, clear, reset, stop } = useCount(
    8000,
    testimonials.length - 1,
    isVisible
  )

  const handleDotClick = (idx) => {
    stop(idx)
    setAnimationActive(false)
  }

  useEffect(() => {
    if (animationActive) {
      if (!isVisible) {
        clear()
        setAnimationActive(false)
      }
    } else {
      if (isVisible) {
        setAnimationActive(true)
        reset()
      }
    }
    // eslint-disable-next-line
  }, [isVisible])

  useEffect(
    () => () => {
      clear()
    },
    // eslint-disable-next-line
    []
  )

  const testimonial = testimonials[count]
  // const testimonial = testimonials[0]

  const animation = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: isVisible ? 1 : 0,
    },
  }

  const barAnimation = {
    hidden: {
      width: "0%",
      position: "relative",
    },
    visible: {
      width: isVisible ? "100%" : "0%",
      position: "relative",
    },
    // exit: {
    //   zIndex: -99,
    //   position: "absolute",
    // },
  }

  return (
    <StyledTestimonials className="testimonials">
      {testimonial && (
        <motion.div
          className="testimonialContainer"
          key={testimonial.id}
          initial="hidden"
          variants={animation}
          animate="visible"
          transition={{
            opacity: { duration: 2 },
          }}
        >
          {/* Testimonial */}
          <Testimonial {...testimonial} />

          {/* BAR */}
          {showBar && animationActive && (
            <BarWrap className="barWrap">
              <Bar
                variants={barAnimation}
                initial="hidden"
                animate="visible"
                transition={{
                  duration: 8,
                  ease: "linear",
                }}
              />
            </BarWrap>
          )}
        </motion.div>
      )}

      <DotsWrap>
        {map(dots, (idx) => (
          <button
            aria-label="next testimonial"
            onClick={() => handleDotClick(idx)}
            key={idx}
            className={classNames("dot", { active: idx === count })}
          />
        ))}
      </DotsWrap>
    </StyledTestimonials>
  )
}

const StyledTestimonials = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.laptop} {
    width: 100%;
  }
`

const Testimonial = ({ quote, author, position, logo, authorPicture }) => {
  return (
    <StyledTestimonial className="testimonial">
      <Media className="logo" media={{ desktop: logo }} />
      {/* <Logo className="logo" {...logo.file} /> */}

      {/* <Media className="logo" media={{ desktop: logo }} /> */}

      <h3 className="quote">"{quote.childMarkdownRemark.rawMarkdownBody}"</h3>

      <div className="authorWrap">
        {authorPicture && (
          <Media className="authorPicture" media={{ desktop: authorPicture }} />
        )}

        <div>
          <p className="author">{author}</p>
          {position && <p className="position">{position}</p>}
        </div>
      </div>
    </StyledTestimonial>
  )
}

const StyledTestimonial = styled(motion.div)`
  /* height: 600px; */

  .logo {
    display: inline-block;
    position: relative;
  }

  .quote {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 24px;
    color: var(--bg1);
    margin-top: var(--sp-32);

    @media ${device.laptop} {
      font-size: 1.75rem;
      line-height: 32px;
    }
  }

  .authorWrap {
    display: grid;

    grid-template-columns: auto 1fr;
    align-items: center;
    grid-gap: var(--sp-16);
    margin-top: var(--sp-32);

    .authorPicture {
      width: 100%;
      max-width: 107px;
    }
    .author {
      color: var(--bg1);
      font-weight: 700;
    }
  }
  .position {
    color: var(--blueGreen);
    font-weight: 700;
    text-transform: uppercase;
  }
`

const DotsWrap = styled.div`
  display: grid;
  grid-gap: 40px;
  grid-template-columns: repeat(auto-fit, 16px);
  align-items: center;
  position: absolute;
  width: 100%;

  /* bottom: 200px; */
  bottom: 10vh;

  /* @media ${device.laptopHeight} {
    border: 2px solid red;
  }
  @media ${device.desktopHeight} {
    border: 2px solid blue;
  } */

  .dot {
    border: 1px solid rgba(77, 116, 121, 0.64);
    background: rgba(77, 116, 121, 0.32);
    border-radius: 100%;
    width: 16px;
    height: 16px;
    transition: 0.2s all ease-in-out;
    transition-property: background, border;
    cursor: pointer;

    &.active {
      border: 1px solid var(--blueGreen);
      background: var(--blueGreen);
    }

    &:hover {
      background: var(--blueGreen);
      opacity: 0.7;
    }
  }
`

const BarWrap = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  z-index: 2;
`

const Bar = styled(motion.div)`
  height: 8px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;

  background: linear-gradient(
    90deg,
    #9dae5c 12.37%,
    #93b09a 31.27%,
    #88aeba 50.51%,
    #8ba0c9 70.37%,
    #809af4 94.86%
  );

  /* background: linear-gradient(
    90deg,
    rgb(157, 174, 92, 0.63),
    rgb(147, 176, 154, 0.63),
    rgb(136, 174, 186, 0.63),
    rgb(139, 160, 201, 0.63),
    rgb(128, 154, 244, 0.63)
  ); */
`

Testimonials.defaultProps = {
  isVisible: false,
}

export default Testimonials
