import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

// import Arrow from "images/common/arrow.inline.svg"

import { useTracking, device } from "utils"

/**
 * Button
 * @param {object} props
 * @param {boolean} props.small
 * @param {('default')} props.theme
 * @param {object} props.track
 * @param {string} props.track.action
 * @param {string} props.track.category
 * @param {string} props.track.label
 *
 * @example
 * <Button theme="black" small>Test</Button>
 */

const Button = ({ children, track, onClick, disabled, ...other }) => {
  const [trackClick] = useTracking(track)

  const handleClick = () => {
    if (typeof onClick === "function") {
      onClick()
    }
    if (!disabled) {
      trackClick()
    }
  }

  return (
    <StyledButton onClick={handleClick} disabled={disabled} {...other}>
      {/* {children} <Arrow className="button_arrow" /> */}
      {children}
    </StyledButton>
  )
}

// SMALL
const FONT_SIZE_SMALL = "12px"
const PADDING_SMALL = "8px 24px"

// DEFAULT
const FONT_SIZE = "14px"
const PADDING = "14px 32px"
const LINE_HEIGHT = "24px"
const FONT_WEIGHT = "700"
const BORDER_RADIUS = "24px"

const COLORS = {
  default: {
    background: "var(--spruce)",
    color: "white",
    border: "1px solid var(--spruce)",
    hover: {
      background: "var(--teal2)",
    },
  },
  alt: {
    background: "white",
    color: "var(--dirt)",
    border: "1px solid var(--dirt)",
    hover: {
      background: "#f5f5f5",
    },
  },
}

const StyledButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: var(--primary-font-family);
  letter-spacing: 0.08em;
  font-style: normal;
  text-align: center;
  text-decoration: none;
  border-radius: ${BORDER_RADIUS};
  font-weight: ${FONT_WEIGHT};
  /* line-height: ${LINE_HEIGHT}; */
  /* max-height: 50px; */

  background: ${({ theme }) =>
    COLORS[theme].background || COLORS["default"].background};
  color: ${({ theme }) => COLORS[theme].color || COLORS["default"].color};
  border: ${({ theme }) => COLORS[theme].border || COLORS["default"].border};

  font-size: ${({ small }) => (small ? FONT_SIZE_SMALL : FONT_SIZE)};
  box-shadow: var(--button-shadow);
  text-transform: uppercase;

  transition: 0.1s all ease-in-out;
  transition-property: opacity, background;
  padding: 14px 24px;

  @media ${device.laptop} {
    padding: ${({ small }) => (small ? PADDING_SMALL : PADDING)};
  }

  &:hover {
    background: ${({ theme }) =>
      COLORS[theme].hover.background || COLORS["default"].hover.background};

    .button_arrow {
      transform: translateX(var(--sp-8));
    }
  }

  .button_arrow {
    margin-left: var(--sp-12);
    transition: transform 0.2s ease-in-out;
    will-change: transform;
    path {
      fill: ${({ theme }) => (theme === "yellow" ? "#001127" : "")};
    }
  }

  ${({ theme }) =>
    theme === "gradient" &&
    `
    border: 2px solid transparent;
    background-image: linear-gradient(${COLORS[theme].background}, ${COLORS[theme].background}), ${COLORS[theme].gradient};
    background-origin: border-box;
    background-clip: padding-box, border-box;
  `};
`

Button.propTypes = {
  track: PropTypes.shape({
    action: PropTypes.string,
    category: PropTypes.string,
    label: PropTypes.string.isRequired,
  }).isRequired,
  small: PropTypes.bool,
  theme: PropTypes.oneOf(["default", "alt"]).isRequired,
}

Button.defaultProps = {
  theme: "default",
  small: false,
  track: {
    action: "select_content",
    category: "CTA",
    label: "",
  },
}

export default Button
