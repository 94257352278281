import React, { useState, useEffect } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { motion, AnimatePresence } from "framer-motion"
import { Portal } from "react-portal"
import { device } from "utils"

/**
 * @description Modal Component
 * @typedef {{
 *  trigger: React.Component
 *  children: React.Component
 * }}
 *
 * Props
 * @type React.FunctionComponent<Props>
 *
 * @example
 * <Modal trigger={<Button>test</Button>}>Test</Modal>
 */

const Modal = ({ children, trigger }) => {
  const [isVisible, setIsVisible] = useState(false)

  const handleClick = () => {
    setIsVisible(true)
    document.body.style.overflow = "hidden"
  }
  const close = () => {
    document.body.style.overflow = "auto"
    setIsVisible(false)
  }

  // clean up
  useEffect(() => {
    return () => {
      close()
    }
    // eslint-disable-next-line
  }, [])

  const modal = {
    from: {
      opacity: 0,
      transition: {
        when: "afterChildren",
        // delay: 2,
      },
    },
    to: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
      },
    },
  }

  const closed = {
    from: {
      opacity: 0,
      scale: 0,
    },
    to: {
      opacity: 1,
      scale: 1,
      transition: {
        delay: 0.1,
      },
    },
  }

  const wrap = {
    from: {
      opacity: 0,
      scale: 0,
      transition: {
        delay: 0.1,
      },
    },
    to: {
      opacity: 1,
      scale: 1,
    },
  }

  return (
    <>
      {React.cloneElement(trigger, { onClick: handleClick })}

      <AnimatePresence>
        {isVisible && (
          <Portal>
            <StyledModal
              key="Modal"
              initial="from"
              animate="to"
              variants={modal}
              exit="from"
            >
              <Close variants={closed} onClick={close}>
                &#10005;
              </Close>
              <Wrap variants={wrap}>
                <Container>
                  {/* {React.cloneElement(children, { closeModal: close })} */}
                  {children}
                </Container>
              </Wrap>
            </StyledModal>
          </Portal>
        )}
      </AnimatePresence>
    </>
  )
}

const StyledModal = styled(motion.div)`
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(2px);
  overflow: auto;
`

const Close = styled(motion.button)`
  position: absolute;
  color: white;
  text-align: center;
  cursor: pointer;
  top: 10px;
  right: 10px;
  font-size: 20px;
  @media ${device.laptop} {
    top: 20px;
    right: 20px;
    font-size: 30px;
  }
`

const Wrap = styled(motion.div)`
  position: relative;
  max-height: 80vh;
`

const Container = styled.div`
  background: white;
  border-radius: var(--card-radius);
  box-shadow: var(--card-shadow);
  padding: var(--sp-16);
`

Modal.defaultProps = {
  trigger: <button>trigger here</button>,
  children: <h1>children here</h1>,
}

Modal.propTypes = {
  trigger: PropTypes.element.isRequired,
  children: PropTypes.element.isRequired, // needs to be react component if passing onClose...
}

export default Modal
