import React from "react"
import { motion, useTransform, useViewportScroll } from "framer-motion"

import { useScrollProgress } from "utils"

/**
 * @description Parallax Component
 * @typedef {{
 *  style: {}
 *  className: string
 *  type: 'x' | 'y' | 'opacity' | 'transform'
 *  offset: number
 *  output: []
 * }}
 *
 * Props
 * @type React.FunctionComponent<Props>
 *
 * @example
 * <Paralax type="x" output=[-100, 0, 0 100] offset="200">Test</Button>
 */

// ********************
// IMPORTANT for useParallax -- older...

// position: relative; on parent will break scroll.......

// position: absolute alternative
// parent:
// display: grid;
// grid-template-columns: 1;
// child:
// grid-row-start: 1;
// grid-column-start: 1;
// ********************

const Parallax = ({
  children,
  type,
  output,
  mobileOutput,
  offset,
  style,
  className,
}) => {
  const { scrollYProgress } = useViewportScroll()
  let isDesktop = { matches: false }
  if (typeof window !== "undefined") {
    isDesktop = window.matchMedia("(min-width: 1024px)")
  }

  const { ref, start, end } = useScrollProgress(offset)

  const parallaxStyle = useTransform(
    scrollYProgress,
    [start, end],
    isDesktop.matches ? output : mobileOutput ? mobileOutput : output
  )

  // const { ref, scrollY, elementPos } = useParallax()

  // const scrollInput = [
  //   elementPos.bottom,
  //   elementPos.bottom + offset,
  //   elementPos.top - offset,
  //   elementPos.top,
  // ]

  //   const y = useTransform(scrollY, scrollInput, [0, -1], {
  //     clamp: false,
  //   })

  // const parallaxStyle = useTransform(scrollY, scrollInput, output)

  return (
    <motion.div
      className={`parallax ${className ? className : ""}`}
      ref={ref}
      style={{ ...style, [type]: parallaxStyle }}
    >
      {children}
    </motion.div>
  )
}

Parallax.defaultProps = {
  // output: [1, 0, 0, 1],
  output: [1, 0],
  type: "opacity",
}

export default Parallax
