import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Media, Video, Nav } from "components/common"
import { device } from "utils"

/**
 * @description Section Container and Max
 * @typedef {{
 *  nav: React.FunctionComponent
 *  video: {file: {url: String}}
 *  image: {width: String, height: String, src: String, alt: String, fixed: {height: Number width: Number}, fluid: {sizes: String}}
 *  mobileImage: {width: String, height: String, src: String, alt: String, fixed: {height: Number width: Number}, fluid: {sizes: String}}
 *  className: String
 *  critical: boolean=false
 * }}
 * Props
 * @type React.FunctionComponent<Props>
 */

const Section = ({
  children,
  backgroundImage,
  className,
  nav,
  showNav,
  critical,
}) => {
  const video =
    backgroundImage &&
    backgroundImage.file &&
    backgroundImage.file.contentType === "video/mp4"
      ? backgroundImage.file.url
      : null

  return (
    <StyledSection className={className}>
      <Background className="sectionBackground">
        {backgroundImage && (
          <Media
            media={backgroundImage}
            className="sectionBackground_image"
            critical={critical}
          />
        )}
        {video && <Video src={video} />}
      </Background>

      {/* navigation */}
      {nav ? <NavWrap>{nav()}</NavWrap> : showNav && <Nav />}
      {/* /navigation */}

      <SectionPadding className="sectionPadding">
        <SectionMax className="sectionMax">{children}</SectionMax>
      </SectionPadding>
    </StyledSection>
  )
}

const NavWrap = styled.div`
  position: relative;
  z-index: 2;
`

const StyledSection = styled.section`
  position: relative;
  width: 100%;
  box-shadow: var(--section-shadow);
  /* overflow: hidden; // needs to be removed.... */
  overflow: var(--section-overflow);
`

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: var(--section-background-color);

  .video {
    video {
      object-fit: cover !important;
    }
  }

  .sectionBackground_image {
    width: 100%;
    height: 100%;

    img {
      height: 100%;
      width: 100%;
      /* object-fit: cover !important; */
    }
  }
`

const SectionPadding = styled.div`
  position: relative;
  z-index: 2;
  padding: var(--section-mobile-padding-top) var(--section-mobile-padding-right)
    var(--section-mobile-padding-bottom) var(--section-mobile-padding-left);

  @media ${device.tablet} {
    padding: var(--section-mobile-padding-top) var(--section-padding-right)
      var(--section-mobile-padding-bottom) var(--section-padding-left);
  }

  @media ${device.laptop} {
    padding: var(--section-padding-top) var(--section-padding-right)
      var(--section-padding-bottom) var(--section-padding-left);
  }
`

const SectionMax = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: var(--section-max-width);

  display: flex;
  flex-direction: column;
`

Section.defaultProps = {
  nav: null,
  critical: false,
  showNav: false,
  image: {},
  mobileImage: {},
}

Section.propTypes = {
  critical: PropTypes.bool,
  image: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
    src: PropTypes.string,
    alt: PropTypes.string,
    fixed: PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
    }),
    file: PropTypes.shape({
      url: PropTypes.string.isRequired,
      fileName: PropTypes.string,
    }),
    fluid: PropTypes.shape({ sizes: PropTypes.string.isRequired }),
  }),
  mobileImage: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
    src: PropTypes.string,
    alt: PropTypes.string,
    fixed: PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
    }),
    file: PropTypes.shape({
      url: PropTypes.string.isRequired,
      fileName: PropTypes.string,
    }),
    fluid: PropTypes.shape({ sizes: PropTypes.string.isRequired }),
  }),
}

export default Section
