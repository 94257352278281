import { useState } from "react"

const useLocalState = () => {
  const [loading, setLoading] = useState(false)
  const [error, _setError] = useState(false)
  const [success, setSuccess] = useState(false)

  const resetLocalState = () => {
    setLoading(false)
    setError(false)
    setSuccess(false)
  }

  const setError = (error) => {
    _setError(error)

    if (typeof window !== "undefined" && window.Sentry) {
      window.Sentry.captureException(error)
    }
  }

  return {
    loading,
    error,
    success,
    setLoading,
    setError,
    setSuccess,
    resetLocalState,
  }
}

export default useLocalState
