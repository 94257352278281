import React from "react"
import styled from "styled-components"
import { Card, TextGroup } from "components/common"
import { useRichText, featureColors, device } from "utils"

import Arrow from "src/images/common/arrow.inline.svg"

const colorKey = {
  product_suppy_chain: featureColors.supplyChain,
  product_compliance: featureColors.compliance,
  product_finances: featureColors.finances,
  product_productivity: featureColors.productivity,
  product_profitability: featureColors.profitability,
  product_customization: featureColors.customization,
}

const FeatureCard = ({ vpId, hat, title, subtitle, media, to }) => {
  return (
    <StyledFeatureCard>
      <Card
        as="div"
        className="featureCard"
        to={to}
        media={media}
        custom={() => (
          <RenderCustom
            vpId={vpId}
            hat={hat}
            title={title}
            subtitle={subtitle}
          />
        )}
        background
      />
    </StyledFeatureCard>
  )
}

const StyledFeatureCard = styled.li`
  position: relative;
  --tg-grid-gap: var(--sp-4);
  .featureCard {
    height: 100%;
    .arrow {
      position: absolute;
      top: var(--sp-32);
      right: var(--sp-32);

      @media ${device.laptop} {
        display: none;
      }
    }

    .cardImage {
      margin-bottom: var(--sp-8);
    }
  }
`

const RenderCustom = ({ vpId, hat, title, subtitle }) => {
  const color = colorKey[vpId]

  const renderTitle = useRichText({
    text: title,
    as: "span",
  })
  return (
    <>
      <Arrow className="arrow" />
      <Custom color={color}>
        <TextGroup hat={hat} hatAs="p" />
        <h5 className="title">
          <span className="animated_underline">{renderTitle}</span>
        </h5>
        <TextGroup subtitle={subtitle} subtitleAs="pSmall" />
      </Custom>
    </>
  )
}

const Custom = styled.div`
  .hat {
    font-weight: 700;
    font-size: 1rem;
    letter-spacing: 0.08em;
    --tg-hat-color: ${({ color }) => color};
  }

  .subtitle {
    margin-top: var(--sp-16);
  }
  .title {
    .animated_underline {
      /* --primary-gradient: linear-gradient(red, orange); */
      --primary-gradient: ${({ color }) =>
        `linear-gradient(${color}, ${color});`};
    }
  }
`

export default FeatureCard
