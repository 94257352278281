import React, { useEffect, useState } from "react"
import styled from "styled-components"
import map from "lodash/map"

import { motion } from "framer-motion"

import { Media } from "components/common"

import { useCount, glassStyle, device } from "utils"
import classNames from "classnames"

const Testimonials = ({ testimonials, isVisible }) => {
  const [animationActive, setAnimationActive] = useState(false)

  const showBar = testimonials.length > 1

  const { count, clear, reset, stop } = useCount(
    8000,
    testimonials.length - 1,
    isVisible
  )

  const handleLogoClick = (idx) => {
    stop(idx)
    setAnimationActive(false)
  }

  useEffect(() => {
    if (animationActive) {
      if (!isVisible) {
        clear()
        setAnimationActive(false)
      }
    } else {
      if (isVisible) {
        setAnimationActive(true)
        reset()
      }
    }
    // eslint-disable-next-line
  }, [isVisible])

  useEffect(
    () => () => {
      clear()
    },
    // eslint-disable-next-line
    []
  )

  const testimonial = testimonials[count]
  // const testimonial = testimonials[0]

  const animation = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: isVisible ? 1 : 0,
      // zIndex: 1,
    },
    // exit: {
    //   opacity: 0,
    //   zIndex: -99,
    //   position: "absolute",
    // },
  }

  const barAnimation = {
    hidden: {
      width: "0%",
      position: "relative",
    },
    visible: {
      width: isVisible ? "100%" : "0%",
      position: "relative",
    },
    exit: {
      zIndex: -99,
      position: "absolute",
    },
  }

  return (
    <StyledTestimonials className="testimonials">
      {testimonial && (
        <motion.div
          className="testimonialContainer"
          key={testimonial.id}
          initial="hidden"
          variants={animation}
          animate="visible"
          // exit="exit"
          transition={{
            // duration: 1,
            opacity: { duration: 0.5 },
          }}
        >
          <Testimonial {...testimonial} />
          {showBar && animationActive && (
            <BarWrap className="barWrap">
              <Bar
                variants={barAnimation}
                initial="hidden"
                animate="visible"
                transition={{
                  duration: 8,
                  ease: "linear",
                }}
              />
            </BarWrap>
          )}
        </motion.div>
      )}

      {testimonial && (
        <ToggleIcon
          testimonials={testimonials}
          testimonial={testimonial}
          handleClick={handleLogoClick}
        />
      )}
    </StyledTestimonials>
  )
}

const StyledTestimonials = styled.div`
  ${glassStyle};
  padding: var(--sp-32) var(--sp-24);
  display: flex;
  flex-direction: column;
  position: relative;

  /* height: var(--testimonial-mobile-height); */

  @media ${device.mobileM} {
    /* height: var(--testimonial-mobileM-height); */
  }

  @media ${device.laptop} {
    /* height: var(--testimonial-height); */
    padding: var(--sp-64);
    width: 100%;
    max-width: 780px;
  }

  .testimonialContainer {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
`

const Testimonial = ({ quote, author, position }) => {
  return (
    <StyledTestimonial className="testimonial">
      <h3 className="quote">"{quote.childMarkdownRemark.rawMarkdownBody}"</h3>
      <p className="author">{author}</p>
      <p className="companyName">{position}</p>
    </StyledTestimonial>
  )
}

const StyledTestimonial = styled(motion.div)`
  position: relative;

  /* this prevents bouncing?????????????????????????????????????????????????????????????????? */

  /* height: 370px;

  @media ${device.mobileM} {
    height: 300px;
  }

  @media ${device.laptop} {
    height: 300px;
  } */

  .quote {
    font-weight: 500;
    color: var(--dirt);
    font-size: 1.25rem;
    line-height: 32px;

    @media ${device.laptop} {
      font-size: 2rem;
      line-height: 40px;
    }
  }
  .author {
    margin-top: var(--sp-16);
  }
  .companyName {
    font-weight: 500;
  }
`

const ToggleIcon = ({ testimonials, testimonial, handleClick }) => {
  const renderToggleIcons = () =>
    map(testimonials, ({ logo, id, integration }, idx) => {
      const isRetina =
        logo.file.contentType === "image/png" &&
        logo.file.details.image.width > 90

      return (
        // eslint-disable-next-line
        <li
          style={{ maxWidth: isRetina && logo.file.details.image.width / 2 }}
          key={id}
          className={classNames("testimonial-logo", {
            active: testimonial.id === id,
            inactive: testimonial.id !== id,
          })}
          // className={testimonial.id === id ? "active" : "inactive"}
          onClick={() => handleClick(idx)}
        >
          <Media className="toggleIcon" media={{ desktop: logo }} />
          {/* <img
            alt={logo.description}
            src={logo.file.url}
          /> */}
        </li>
      )
    })

  return <StyledLogos>{renderToggleIcons()}</StyledLogos>
}

const StyledLogos = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, auto);

  width: 100%;
  align-items: center;
  justify-content: flex-start;

  grid-gap: var(--sp-4);

  margin-top: var(--sp-16);

  @media ${device.laptop} {
    grid-gap: var(--sp-32);
    grid-row-gap: var(--sp-16);
  }

  li {
    filter: grayscale(1) opacity(0.32);
    cursor: pointer;
    transition: 0.1s filter ease-in-out;
    will-change: filter;
    margin: var(--sp-8) var(--sp-16);

    &:hover {
      filter: grayscale(0) opacity(1);
    }

    &.active {
      filter: grayscale(0) opacity(1);
    }
  }

  img {
    width: 100%;
  }
`

const BarWrap = styled.div`
  position: absolute;
  bottom: -2px;
  left: var(--sp-24);
  right: var(--sp-24);

  @media ${device.laptop} {
    left: var(--sp-64);
    right: var(--sp-64);
  }
`

const Bar = styled(motion.div)`
  height: 2px;
  border-radius: 24px;
  /* background: linear-gradient(
    90deg,
    rgba(157, 174, 92, 0.63) 12.37%,
    rgba(147, 176, 154, 0.63) 31.27%,
    rgba(136, 174, 186, 0.63) 50.51%,
    rgba(139, 160, 201, 0.63) 70.37%,
    rgba(128, 154, 244, 0.63) 94.86%
  ); */
  background: linear-gradient(
    90deg,
    rgb(157, 174, 92, 0.63),
    rgb(147, 176, 154, 0.63),
    rgb(136, 174, 186, 0.63),
    rgb(139, 160, 201, 0.63),
    rgb(128, 154, 244, 0.63)
  );
`

Testimonials.defaultProps = {
  isVisible: false,
}

export default Testimonials
