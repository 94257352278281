import { useMemo } from "react"

const useRandomNoRepeats = (array) => {
  function randomNoRepeats(array) {
    var copy = array.slice(0)
    return function () {
      if (copy.length < 1) {
        copy = array.slice(0)
      }
      var index = Math.floor(Math.random() * copy.length)
      var item = copy[index]
      copy.splice(index, 1)
      return item
    }
  }

  return useMemo(() => {
    const item = randomNoRepeats(array)
    return item()
  }, [array])
}

export default useRandomNoRepeats
