import React, { useEffect } from "react"
import styled from "styled-components"
import map from "lodash/map"
import groupBy from "lodash/groupBy"
import { Portal } from "react-portal"
import { motion, AnimatePresence } from "framer-motion"
import classNames from "classnames"

import Close from "images/common/close.inline.svg"

import { device } from "utils"

import { Link, FeatureIcon, Button } from "components/common"

const sideNavAni = {
  exit: {
    opacity: 0,
  },
  from: {
    opacity: 0,
    scale: 0.8,
  },
  to: {
    opacity: 1,
    scale: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.05,
    },
  },
}

const linkAni = {
  from: {
    opacity: 0,
    x: 80,
  },
  to: {
    opacity: 1,
    x: 0,
    transition: {
      staggerChildren: 0.05,
    },
  },
}

const MobileSideNav = ({ open, close, navigation }) => {
  useEffect(() => {
    return () => {
      document.body.style.overflow = "auto"
      close()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
  }, [open])

  return (
    <AnimatePresence>
      {open && (
        <Portal>
          <SideNav
            key="SideNav"
            initial="from"
            animate="to"
            variants={sideNavAni}
            exit="exit"
          >
            <motion.div
              className="logoWrap"
              variants={{
                from: { opacity: 0 },
                to: { opacity: 1 },
              }}
            >
              <span />
              <button onClick={close} className="close">
                <Close />
              </button>
            </motion.div>

            <ul className="navigationLinks">
              {map(navigation, ({ category, navigation, id }) => {
                if (category === "Integrations") {
                  const { software, hardware } = groupBy(navigation, "category")
                  return (
                    <motion.li variants={linkAni} key={id}>
                      <h3>{category}</h3>
                      <RenderNavigationGrid
                        category={category}
                        subCategory="Software"
                        navigation={software}
                        subClassName="software"
                      />
                      <RenderNavigationGrid
                        category={category}
                        subCategory="Hardware"
                        navigation={hardware}
                        subClassName="hardware"
                      />
                    </motion.li>
                  )
                } else {
                  return (
                    <motion.li variants={linkAni} key={id}>
                      {category !== "cta" && <h3>{category}</h3>}
                      <RenderNavigationGrid
                        category={category}
                        navigation={navigation}
                      />
                    </motion.li>
                  )
                }
              })}
            </ul>
          </SideNav>
        </Portal>
      )}
    </AnimatePresence>
  )
}

const RenderNavigationGrid = ({
  category,
  navigation,
  subCategory,
  subClassName,
}) => {
  return (
    <NavigationGrid className={category}>
      {subCategory && (
        <h5 className={classNames(subClassName, "subCategoryTitle")}>
          {subCategory}
        </h5>
      )}

      {map(navigation, ({ text, id, to, href }, idx) => (
        <motion.li variants={linkAni} key={text}>
          {category === "cta" ? (
            <Link to={to} href={href}>
              <Button theme={idx === 0 ? "alt" : "default"}>{text}</Button>
            </Link>
          ) : (
            <Link
              className={`link ${id || "bullet"}`}
              to={to}
              href={href}
              activeClassName="active"
            >
              <FeatureIcon id={id || "bulletArrow"} />
              {text}
            </Link>
          )}
        </motion.li>
      ))}
    </NavigationGrid>
  )
}

const SideNav = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background: white;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: var(--section-mobile-padding);

  /* background: rgba(2, 15, 35, 0.48);
  backdrop-filter: blur(24px);

  @-moz-document url-prefix() {
    background: rgba(2, 15, 35, 0.9);
  } */

  .logoWrap {
    display: flex;
    justify-content: space-between;

    .close {
      path {
        stroke: var(--dirt);
      }
    }
  }

  .navigationLinks {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: var(--sp-48);

    h3 {
      font-size: 1.5rem;
      line-height: 32px;
      color: var(--soil);
      margin-bottom: var(--sp-16);
    }
  }
`

const NavigationGrid = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--sp-16);

  @media ${device.mobileM} {
    grid-template-columns: 1fr 1fr;

    &.Integrations {
      grid-template-columns: 1fr;
    }
  }

  .subCategoryTitle {
    color: var(--dirt);
    font-size: 0.875rem;
    font-weight: bold;
    line-height: 32px;
    letter-spacing: 0.08em;
    text-transform: uppercase;

    &.hardware {
      margin-top: var(--sp-16);
    }
  }

  .link {
    display: grid;
    grid-template-columns: 32px 1fr;
    grid-gap: var(--sp-8);
    align-items: center;
    font-weight: 500;
    color: var(--dirt);
    font-size: 0.875rem;
    svg {
      width: 32px;
      height: 32px;
    }

    &.bullet {
      grid-template-columns: 24px 1fr;
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  /* category */
  &.cta {
    grid-gap: var(--sp-16);

    a {
      grid-template-columns: 1fr;

      button {
        width: 100%;
      }
    }
  }
`

export default MobileSideNav
