import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

function Seo({ description, lang, meta, title, image, keywords, author }) {
  const httpsImage = image && image.includes("https:")
  const metaImage = httpsImage ? image : `https:${image}`

  console.log({incomingmeta: meta});

  const preparedMeta = [
    {
      name: `description`,
      content: description,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: description,
    },
    {
      name: `image`,
      content: metaImage,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      property: `og:image`,
      content: metaImage,
    },
    {
      name: "keywords",
      content: keywords,
    },
    {
      name: `twitter:card`,
      content: `summary_large_image`,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: description,
    },
    {
      name: `twitter:image`,
      content: metaImage,
    },
  ].concat(meta);

  console.log({preparedMeta})

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={preparedMeta}
    />
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export default Seo
