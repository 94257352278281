import React from "react"
import { device } from "src/utils"
import styled from "styled-components"

const SideBar = ({ sideBar: Bar, children }) => {
  return (
    <StyledSideBar>
      <StyledBar>
        <Bar />
      </StyledBar>

      <StyledChildren>{children}</StyledChildren>
    </StyledSideBar>
  )
}

const StyledSideBar = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  height: 100%;
  @media ${device.laptop} {
    grid-template-columns: var(--sideBar_width) 1fr;
  }
`

const StyledBar = styled.div`
  background: var(--sideBar_background);
  align-items: center;
  justify-content: center;
  display: none;
  position: relative;
  padding: var(--sideBar-mobile_padding);

  @media ${device.laptop} {
    padding: var(--sideBar_padding);
    display: flex;
  }
`

const StyledChildren = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--sideBar-mobile_padding);

  @media ${device.laptop} {
    padding: var(--sideBar_padding);
  }
`

export default SideBar
