import React, { useCallback } from "react"
import styled from "styled-components"

import { TextGroup, Image } from "components/common"

import { device } from "utils"

const EndCap = ({ image, text }) => {
  const renderEnd = useCallback(() => {
    return (
      <>
        <Image image={image} className="end-icon" alt={image.description} />
        <TextGroup className="endCapText" title={text} titleAs="p" />
      </>
    )
  }, [image, text])

  return <StyledEndCap className="endCap">{renderEnd()}</StyledEndCap>
}

const StyledEndCap = styled.div`
  position: absolute;

  width: 100%;
  left: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  display: none;

  @media ${device.laptop} {
    display: flex;
    bottom: var(--sp-48);
  }

  .end-icon {
    margin-right: var(--sp-12);
  }

  .endCapText {
    p,
    a {
      font-weight: 500;
      font-family: "Rubik";
    }
  }

  /* padding: var(--sp-56) 0; */
`

export default EndCap
