import React, { useState } from "react"
import styled from "styled-components"
import { Waypoint } from "react-waypoint"

import { useViewportScroll, motion, useTransform } from "framer-motion"

import { useScrollProgress } from "utils"

const ScrollProgress = ({ children, offset = 300 }) => {
  const [showProgress, setShowProgress] = useState(true)
  const { scrollYProgress } = useViewportScroll()
  const { ref, start, end } = useScrollProgress()

  const parallaxStyle = useTransform(
    scrollYProgress,
    [start, end],
    ["0%", "100%"]
  )

  const onPositionChange = ({ currentPosition, previousPosition }) => {
    if (previousPosition === "inside" && currentPosition === "above") {
      setShowProgress(false)
    }

    if (previousPosition === "above" && currentPosition === "inside") {
      setShowProgress(true)
    }
  }

  return (
    <StyledProgressBar>
      <motion.div
        className="progress"
        style={{ width: parallaxStyle, opacity: showProgress ? 1 : 0 }}
      />
      <div ref={ref}>{children}</div>
      <Waypoint topOffset={offset} onPositionChange={onPositionChange} />
    </StyledProgressBar>
  )
}

const StyledProgressBar = styled.div`
  .progress {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    height: 7px;
    background: var(--primary-gradient);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`

export default ScrollProgress
