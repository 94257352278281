import React from "react"
import styled from "styled-components"
import { glassStyle } from "utils"

import { Link } from "components/common"
import Arrow from "images/common/arrow.inline.svg"

const ValuePropRow = ({ children, to, href, ...other }) => (
  <Link to={to} href={href} {...other}>
    <StyledValuePropRow className="valuePropRow">
      {children} <Arrow />
    </StyledValuePropRow>
  </Link>
)

const StyledValuePropRow = styled.div`
  ${glassStyle};
  display: flex;
  justify-content: space-between;
  padding: var(--sp-16) var(--sp-32);
  align-items: center;
  transition: 0.2s box-shadow ease-in-out;
  will-change: box-shadow;

  svg {
    transition: 0.2s transform ease-in-out;
    will-change: transform;
  }

  &:hover {
    box-shadow: var(--card-shadow-hover);
    svg {
      transform: translateX(8px);
    }
  }
`

export default ValuePropRow
