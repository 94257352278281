import { useRef, useState, useCallback } from "react"

// Hook
const useHover =  () => {
  const [value, setValue] = useState(false)

  // Wrap in useCallback so we can use in dependencies below
  const handleMouseEnter = useCallback(() => setValue(true), [])
  const handlMouseLeave = useCallback(() => setValue(false), [])

  // Keep track of the last node passed to callbackRef
  // so we can remove its event listeners.
  const ref = useRef()

  // Use a callback ref instead of useEffect so that event listeners
  // get changed in the case that the returned ref gets added to
  // a different element later. With useEffect, changes to ref.current
  // wouldn't cause a rerender and thus the effect would run again.
  const callbackRef = useCallback(
    (node) => {
      if (ref.current) {
        ref.current.removeEventListener("mouseenter", handleMouseEnter)
        ref.current.removeEventListener("mouseleave", handlMouseLeave)
      }

      ref.current = node

      if (ref.current) {
        ref.current.addEventListener("mouseenter", handleMouseEnter)
        ref.current.addEventListener("mouseleave", handlMouseLeave)
      }
    },
    [handleMouseEnter, handlMouseLeave]
  )

  return [callbackRef, value]
}

export default useHover