import React from "react"

import styled from "styled-components"

import { device } from "utils"

import Compliance from "src/images/features/compliance.inline.svg"
import Customization from "src/images/features/customization.inline.svg"
import Finances from "src/images/features/finances.inline.svg"
import Integrations from "src/images/features/integrations.inline.svg"
import Productivity from "src/images/features/productivity.inline.svg"
import Profitability from "src/images/features/profitability.inline.svg"
import BulletArrow from "src/images/features/bulletArrow.inline.svg"
import SupplyChain from "src/images/features/supplyChain.inline.svg"

import AllIntegrations from "src/images/integrations/iconIntegrationsAll.inline.svg"
import AllHardware from "src/images/integrations/all_hardware.inline.svg"
import QuickBooks from "src/images/integrations/LogoQuickbooksOnline.inline.svg"
import Sage from "src/images/integrations/sageIntacctLogo.png"
import AllFeatures from "src/images/integrations/allFeaturesMinimal.inline.svg"

// Company

import About from "src/images/nav/company/iconAboutUs.inline.svg"
import Team from "src/images/nav/company/iconTeam.inline.svg"
import Careers from "src/images/nav/company/iconCareers.inline.svg"
import Blog from "src/images/nav/company/iconBlog.inline.svg"
import Press from "src/images/nav/company/iconPress.inline.svg"
import Star from "src/images/nav/company/iconStar.inline.svg"

const FeatureIcon = ({ id, className }) => {
  const key = {
    productivity: Productivity,
    compliance: Compliance,
    customization: Customization,
    finances: Finances,
    integrations: Integrations,
    profitability: Profitability,
    supplyChain: SupplyChain,
    quickbooks: QuickBooks,
    sage: Sage,
    all_integrations: AllIntegrations,
    all_hardware: AllHardware,
    product: AllFeatures,
    bulletArrow: BulletArrow,
    about: About,
    team: Team,
    careers: Careers,
    blog: Blog,
    press: Press,
    why_backbone: Star,
  }

  const Icon = key[id]

  if (Icon && typeof Icon === "function") {
    return <Icon className={className} style={{ overflow: "visible" }} />
  } else if (Icon && typeof Icon === "string") {
    return <StyledIcon src={Icon} alt="integration icon" />
  } else {
    return <span />
  }
}

const StyledIcon = styled.img`
  height: 32px;
  width: 32px;

  @media ${device.laptop} {
    height: 40px;
    width: 40px;
  }
`

export default FeatureIcon
