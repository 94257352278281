import React, { useMemo } from "react"
import styled from "styled-components"
import { Media, ValueProp, Grid } from "components/common"
import { device } from "utils"

const ValuePropSplit = ({ media, valueProps }) => {
  const { leftVP, rightVP } = useMemo(() => {
    const half = Math.floor(valueProps.length / 2)
    const leftVP = valueProps.slice(0, half)
    const rightVP = valueProps.slice(half, valueProps.length)

    return {
      leftVP,
      rightVP,
    }
  }, [valueProps])

  const renderGrid = (vps, flip) => (
    <Grid
      className="splitGrid"
      list={vps}
      component={(vp) => {
        return (
          <ValueProp
            className={flip ? "flip" : ""}
            align="center"
            orientation="horizontal"
            flip={flip}
            {...vp}
          />
        )
      }}
    />
  )

  return (
    <StyledValuePropSplit>
      <ul className="split desktop">
        <li>{renderGrid(leftVP, true)}</li>
        <li className="center">
          <Media media={media} />
        </li>
        <li>{renderGrid(rightVP)}</li>
      </ul>

      <ul className="mobile">
        <li>{renderGrid(valueProps)}</li>
        <li className="mobileMedia_wrap">
          <Media media={media} />
        </li>
      </ul>
    </StyledValuePropSplit>
  )
}

const StyledValuePropSplit = styled.div`
  .mobile {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: var(--sp-16);

    @media ${device.laptop} {
      display: none;
    }
    .subtitle {
      font-weight: 500;
    }

    .mobileMedia_wrap {
      position: relative;
      display: block;
      margin: var(--sp-32) 0 0;

      left: calc(var(--section-mobile-padding) * -1);
      margin-right: calc(var(--section-mobile-padding) * -2);

      img {
        width: 100%;
      }

      @media ${device.tablet} {
        left: calc(var(--sp-64) * -1);
        margin-right: calc(var(--sp-64) * -2);
      }

      @media ${device.laptop} {
        left: initial;
        margin-right: initial;
        margin: var(--sp-56) auto 0;
        img {
          width: initial;
        }
      }
    }
  }

  .desktop {
    display: none;

    @media ${device.laptop} {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      justify-content: center;
      align-items: center;
      grid-gap: var(--sp-24);
      grid-template-columns: auto auto auto;
    }

    .splitGrid {
      .vp_contentWrap {
        grid-template-columns: 22px auto;
        grid-gap: var(--sp-8);

        @media ${device.laptop} {
          grid-gap: var(--sp-24);
        }

        .subtitle {
          font-weight: 500;
        }
      }

      .flip {
        .vp_contentWrap {
          grid-template-columns: auto 22px;
        }
      }
    }
  }
`

export default ValuePropSplit
