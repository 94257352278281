import { css } from "styled-components"

export default css`
  --section-background-color: #fdfcfc;
  --section-shadow: 0px 1.13374px 64px rgba(233, 222, 216, 0.5),
    inset 0px 0.159403px 6.37612px rgba(249, 249, 249, 0.2);

  z-index: 4;
  position: relative;
  backdrop-filter: blur(3px);

  .sectionBackground {
    opacity: 0.8;
  }
`
