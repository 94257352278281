import React, { useState, useRef } from "react"
import styled from "styled-components"

import { TextGroup } from "src/components/common"

export default React.memo((props) => {
  const [setActive, setActiveState] = useState("")
  const [setHeight, setHeightState] = useState("0px")
  // const [setRotate, setRotateState] = useState("accordion__icon")

  const content = useRef(null)

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "")
    setHeightState(
      setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
    )
    // setRotateState(
    //   setActive === "active" ? "accordion__icon" : "accordion__icon rotate"
    // )
  }

  return (
    <Accordion className="accordion">
      <button className={`accordion ${setActive}`} onClick={toggleAccordion}>
        <TextGroup
          titleAs="h5"
          title={props.title}
          className="accordion__title"
        />

        <span className={`toggle-icon ${setActive ? "open" : "close"}`}>
          &#9658;
        </span>
      </button>
      <div
        ref={content}
        style={{ maxHeight: `${setHeight}` }}
        className="accordion__content"
      >
        <TextGroup className="accordion__text" subtitle={props.content} />
      </div>
    </Accordion>
  )
})

const Accordion = styled.div`
  display: flex;
  flex-direction: column;

  .toggle-icon {
    font-size: 0.5rem;
    font-family: var(--title-font-family);
    color: #dee5ef;
    transition: 0.1s transform ease-in-out;
    will-change: transform;

    &.open {
      transform: rotate(90deg);
    }
  }

  /* Style the buttons that are used to open and close the accordion panel */
  .accordion {
    color: var(--primary-font-color);
    cursor: pointer;
    padding: var(--sp-16) 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    outline: none;
  }

  /* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
  /* .accordion:hover,
  .active {
    background-color: #ccc;
  } */

  /* Style the accordion content title */
  .accordion__title {
    .title {
      font-family: var(--primary-font-family);
      margin-bottom: 0;
      font-weight: 500;
    }
  }

  /* Style the accordion chevron icon */
  .accordion__icon {
    margin-left: auto;
    transition: transform 0.2s ease;
  }

  /* Style to rotate icon when state is active */
  .rotate {
    transform: rotate(90deg);
  }

  /* Style the accordion content panel. Note: hidden by default */
  .accordion__content {
    color: var(--primary-font-color);

    overflow: hidden;
    transition: max-height 0.2s ease;

    li {
      list-style-type: disc;
      margin-left: 20px;
    }

    /* ul,ol {
      list-style: initial;
    } */
  }

  /* Style the accordion content text */
  .accordion__text {
    padding: var(--sp-16) 0;
    padding-top: 0;
  }
`
