import React from "react"
import styled from "styled-components"

const Badge = ({ children, className }) => (
  <StyledBadge className={className}>{children}</StyledBadge>
)

const StyledBadge = styled.span`
  padding: var(--sp-8);
  text-align: center;
  border-radius: 35px;
  background: var(--water);
  color: white;
  font-size: 0.75rem;
  line-height: 7px;
`

export default Badge
