import React, { useEffect } from "react"

import { navigate } from "gatsby"

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error })
    if (typeof window !== "undefined" && window.Sentry) {
      window.Sentry.configureScope((scope) => {
        Object.keys(errorInfo).forEach((key) => {
          scope.setExtra(key, errorInfo[key])
        })
      })
      window.Sentry.captureException(error)
    }
  }

  render() {
    if (this.state.error) {
      // render fallback UI
      return <NavigateToErrorPage />
    } else {
      // when there's not an error, render children untouched
      return this.props.children
    }
  }
}

const NavigateToErrorPage = () => {
  useEffect(() => navigate("/400"), [])

  return <div />
}

export default ErrorBoundary
