import React, { useCallback } from "react"
import styled from "styled-components"
import { Link } from "src/components/common"
import { motion, AnimatePresence } from "framer-motion"
import map from "lodash/map"

import { FeatureIcon, Badge } from "components/common"
import { useHover, glassStyle } from "src/utils"

// usage

/* <NavDropdown
key={id}
dropdown={dropdown}
link={() => (
  <LinkTo as="span" className="link" >
    {text}
  </LinkTo>
)}
/> */

const NavDropdown = ({ id, text, dropdown, link: LinkToRender, className }) => {
  const [hoverDropdownRef, dropdownIsHovered] = useHover()

  const renderLinks = useCallback(
    () =>
      map(dropdown, ({ to, text, id, badge }) => (
        <LinkWrap key={to} className="linkWrap">
          <Link activeClassName="active" className={`${id}`} to={to}>
            <FeatureIcon id={id} />

            <span className="text">
              {text}
              {badge && <Badge className="badge">{badge}</Badge>}
            </span>
          </Link>
        </LinkWrap>
      )),
    [dropdown]
  )

  // 1. hover link > open dropdown
  // 2. if leave link without going to dropdown close
  // 3. hover dropdown
  // 4. leave dropdown > close dropdown
  // 4. leave dropdown and hover link > open?

  const variants = {
    hidden: {
      opacity: 0,
      y: 20,
    },
    visible: {
      opacity: 1,
      y: 0,
    },
    exit: {
      opacity: 0,
      y: 20,
    },
  }

  return (
    <DropdownWrap className={className}>
      <LinkToRender />
      <StyledNavDropdown ref={hoverDropdownRef}>
        <AnimatePresence>
          {dropdownIsHovered && (
            <motion.ul
              variants={variants}
              initial="hidden"
              animate="visible"
              exit="exit"
              key={id}
              className="dropdown"
            >
              {renderLinks()}
            </motion.ul>
          )}
        </AnimatePresence>
        {/* {true && <ul className="dropdown">{renderLinks()}</ul>} */}
      </StyledNavDropdown>
    </DropdownWrap>
  )
}

const DropdownWrap = styled.li`
  position: relative;
`

const StyledNavDropdown = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  min-height: 100%;
  /* left: -32px; */
  left: 0;
  width: 100%; // important

  .dropdown {
    margin-top: var(--sp-32);
    width: var(--navDropdown-width);
    padding: var(--sp-32) 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: var(--sp-16);
    ${glassStyle};
    background: rgba(255, 255, 255, 0.96);
  }
`

const LinkWrap = styled.li`
  cursor: pointer;

  &:hover {
    svg {
      #icon {
        /* transform: scale(1.05) translate3d(-4px, -4px, 0); */
        transform: scale(1.05) translate3d(0, -4px, 0);
      }
      #bg {
        transform: scale(0.95) translate3d(4px, 4px, 0);
      }
    }

    a {
      span {
        opacity: 0.8;
      }
      &.supplyChain {
        span {
          opacity: 1 !important;
          color: var(--cannabis) !important;
        }
      }
      &.compliance {
        span {
          opacity: 1 !important;
          color: var(--forest) !important;
        }
      }
      &.finances {
        span {
          opacity: 1 !important;
          color: var(--moss) !important;
        }
      }
      &.productivity {
        span {
          opacity: 1 !important;
          color: var(--seafoam) !important;
        }
      }
      &.profitability {
        span {
          opacity: 1 !important;
          color: var(--shoreline) !important;
        }
      }
      &.customization {
        span {
          opacity: 1 !important;
          color: var(--shoreline) !important;
        }
      }
      &.integrations {
        span {
          opacity: 1 !important;
          color: var(--blueberry) !important;
        }
      }
    }
  }

  a {
    display: grid;
    align-items: center;
    grid-gap: var(--sp-16);
    grid-template-columns: 40px auto;
    padding: 0 var(--sp-32);
    transition: 0.1s all ease-in-out;
    transition-property: opacity, color;
    will-change: opacity, color;

    width: 100%;

    &.active {
      /* font-weight: 700 !important; */

      &.supplyChain {
        color: var(--cannabis) !important;
      }
      &.compliance {
        color: var(--forest) !important;
      }
      &.finances {
        color: var(--moss) !important;
      }
      &.productivity {
        color: var(--seafoam) !important;
      }
      &.profitability {
        color: var(--shoreline) !important;
      }
      &.customization {
        color: var(--shoreline) !important;
      }
      &.integrations {
        color: var(--blueberry) !important;
      }
    }
  }

  svg {
    width: 40px;
    height: 40px;

    overflow: visible;
    #icon,
    #bg {
      transition: 0.1s all ease-in-out;
      /* backface-visibility: hidden; */
    }
  }

  .text {
    display: flex;
    align-items: center;
    font-size: 1rem !important;
    color: var(--primary-font-color);

    font-weight: 500 !important;
  }
  .badge {
    margin-left: var(--sp-8);
  }
`

NavDropdown.defaultProps = {
  color: "default",
  links: [],
  open: false,
}

export default NavDropdown
