import React, { useContext } from "react"
import styled from "styled-components"
import { map, findIndex } from "lodash"

import { Button, Link, Media } from "components/common"

import NavDropdown from "./NavDropdown"
import NavIntegrationsDropdown from "./NavIntegrationsDropdown"

import { device } from "utils"

import { Context as GreenhouseContext } from "src/utils/context/greenhouse"

const DesktopHeader = ({ navigation, logo, ctaText, ctaUrl }) => {
  const {
    state: { jobCount },
  } = useContext(GreenhouseContext)

  return (
    <StyledNav navLength={navigation.length}>
      <ul className="container">
        <li className="logo">
          <Link to="/">
            <Media media={logo} />
          </Link>
        </li>
        <ul className="navigation-list">
          {map(
            navigation,
            ({
              to,
              href,
              text,
              id,
              dropdown,
              category,
              navigation: subNavigation,
            }) => {
              // for company dropdown find carrers and add totalCount badge...
              if (text === "Company") {
                const idx = findIndex(subNavigation, ["id", "careers"])
                subNavigation[idx].badge = jobCount || null
              }

              return dropdown ? (
                category === "integrations" ? (
                  <NavIntegrationsDropdown
                    key={id}
                    id={id}
                    className={`nav-link ${text}`}
                    dropdown={subNavigation}
                    link={() => (
                      <span
                        style={{ cursor: "pointer" }}
                        className="dropdown-toggle"
                      >
                        {text}
                      </span>
                    )}
                  />
                ) : (
                  <NavDropdown
                    key={id}
                    id={id}
                    className={`nav-link ${text}`}
                    dropdown={subNavigation}
                    link={() => (
                      <span
                        style={{ cursor: "pointer" }}
                        className="dropdown-toggle"
                      >
                        {text}
                      </span>
                    )}
                  />
                )
              ) : (
                <li key={id} className="nav-link no-dd">
                  <Link to={to} href={href} activeClassName="active">
                    {text}
                  </Link>
                </li>
              )
            }
          )}
        </ul>
        {ctaUrl && ctaText && (
          <li className="cta">
            <Link to={ctaUrl}>
              <Button>{ctaText}</Button>
            </Link>
          </li>
        )}
      </ul>
    </StyledNav>
  )
}

const StyledNav = styled.nav`
  display: none;
  @media ${device.laptop} {
    display: block;
  }

  .container {
    width: 100%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: auto 1fr auto;
    /* grid-gap: var(--sp-64); */
    grid-gap: 4%;
    align-items: center;
    position: relative;

    @media ${device.desktopL} {
      grid-gap: var(--sp-64);
    }

    .navigation-list {
      display: grid;
      grid-template-columns: ${({ navLength }) =>
        `repeat(${navLength}, auto);`};

      align-items: center;
      justify-content: flex-start;
      grid-gap: 5%;

      @media ${device.desktopL} {
        grid-gap: var(--sp-64);
      }

      .nav-link {
        &.no-dd {
          &:hover {
            opacity: 0.8;
          }
        }

        a,
        .dropdown-toggle {
          font-family: var(--primary-font-family);
          font-weight: 500;
          font-size: 1.125rem;
          font-size: 0.875rem;
          color: var(--nav-link-color);

          transition: 0.1s opacity ease-in-out;
          will-change: opacity;

          @media ${device.tablet} {
            font-size: 1rem !important;
          }

          @media ${device.desktop} {
            font-size: 1.125rem !important;
          }

          &.active {
            color: var(--active-nav-link-color);
            font-weight: 500;
          }
        }
      }

      .Product {
        --navDropdown-width: 230px;
      }
      .Integrations {
        --navDropdown-width: 320px;
        .linkWrap {
          &:hover {
            svg {
              #icon {
                transform: scale(1.03);
              }
            }
          }
        }
      }
      .Company {
        --navDropdown-width: 235px;

        .linkWrap {
          &:hover {
            svg {
              #icon {
                transform: scale(1.03);
              }
            }
          }
        }
      }
    }
  }
`

export default DesktopHeader
