import React, { useState } from "react"
import styled from "styled-components"

import { Grid } from "components/common"

import { motion, AnimateSharedLayout, AnimatePresence } from "framer-motion"

const CrossFadeModal = ({ list, component, children }) => {
  const [selected, setSelected] = useState(null)

  const handleOpen = (item) => {
    setSelected(item)
    document.body.style.overflow = "hidden"
  }
  const handleClose = () => {
    setSelected(null)
    document.body.style.overflow = "auto"
  }

  return (
    <AnimateSharedLayout type="crossfade">
      <Grid
        layout
        list={list}
        component={(item) => (
          <motion.li layoutId={item.id} onClick={() => handleOpen(item)}>
            {component(item)}
          </motion.li>
        )}
      />

      <AnimatePresence>
        {selected && (
          <Modal
            selected={selected}
            setSelected={handleClose}
            children={children}
          />
        )}
      </AnimatePresence>
    </AnimateSharedLayout>
  )
}

const Modal = ({ selected, setSelected, children }) => (
  <StyledModal
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0, transition: { duration: 0.15 } }}
    transition={{ duration: 0.2, delay: 0.15 }}
    style={{ pointerEvents: "auto" }}
    className="overlay"
    onClick={() => setSelected(null)}
  >
    <motion.div
      className="container"
      layoutId={selected.id}
      onClick={(e) => e.stopPropagation()}
    >
      {children(selected)}
    </motion.div>
  </StyledModal>
)

const StyledModal = styled(motion.div)`
  position: fixed;
  z-index: 800;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: auto;
  background: #f17176a8;
  backdrop-filter: blur(2px);
  animation: var(--animation--hue-20);

  .container {
    width: 100%;
    max-width: 650px;
    margin: 0 5vw;
    margin-top: 25vh;
    border-radius: 16px;
    padding: 32px;
    background: white;
    position: relative;

    button {
      position: absolute;
      top: 16px;
      right: 16px;
      font-size: 20px;
    }
  }
`

export default CrossFadeModal
