import React, { useCallback } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import map from "lodash/map"

import { device } from "utils"

/**
 * Renders a <Grid /> component
 * @param {object} props Component Props
 * @param {number} props.col number of columns
 * @param {string} props.mobileGap grid gap
 * @param {number} props.gap grid gap
 * @param {bool} props.toEdge content goes to edge?
 * @param {string} props.width width
 * @param {string} props.mobileWidth mobile width
 * @param {('left' | 'center' | 'right')} props.align align columns
 * @param {[{id: string | number}]} props.list
 * @example
 * <Grid list=['a] component={() => <div></div>} />
 */
const Grid = ({
  col,
  align,
  width,
  mobileWidth,
  gap,
  mobileGap,
  list,
  component,
  children,
  toEdge,
  ...other
}) => {
  const items = useCallback(
    () => renderList(list, component),
    [list, component]
  )

  return (
    <StyledGrid
      align={align}
      col={col || items.length}
      width={width}
      mobileWidth={mobileWidth}
      gap={gap}
      mobileGap={mobileGap}
      toEdge={toEdge}
      {...other}
    >
      {list.length ? items() : children}
    </StyledGrid>
  )
}

const renderList = (list, Component) => {
  return map(list, (props) => {
    const { id } = props
    return <Component key={id} {...props} />
  })
}

const alignLib = {
  left: "flex-start",
  right: "flex-end",
  center: "center",
}

const StyledGrid = styled.ul`
  display: grid;
  justify-content: ${({ align }) => alignLib[align]};
  /* grid-gap: var(--sp-16); */
  grid-gap: ${({ mobileGap }) => mobileGap};

  grid-template-columns: ${({ mobileWidth }) =>
    mobileWidth ? `repeat(auto-fit, minmax(auto, ${mobileWidth}))` : "1fr"};

  @media ${device.laptop} {
    grid-gap: ${({ gap }) => `var(--sp-${gap})`};

    grid-template-columns: ${({ col, width, toEdge }) =>
      col && !toEdge
        ? `repeat(${col}, minmax(auto, ${width}))`
        : toEdge && col
        ? `repeat(${col}, minmax(auto, 1fr))`
        : toEdge
        ? `repeat(auto-fill, minmax(${width}, 1fr))`
        : `repeat(auto-fill, minmax(auto,${width}))`};
  }
`

Grid.defaultProps = {
  align: "left",
  list: [],
  children: null,
  component: <span />,
  // col: 3,
  width: "300px",
  mobileWidth: null,
  gap: 24,
  mobileGap: "16px",
  toEdge: false, // fill space and go to edges = col only
}
Grid.propTypes = {
  align: PropTypes.oneOf(["left", "right", "center"]).isRequired,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }).isRequired
  ),
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  col: PropTypes.number,
  width: PropTypes.string.isRequired,
  mobileWidth: PropTypes.string,
  gap: PropTypes.number.isRequired,
  mobileGap: PropTypes.string,
  toEdge: PropTypes.bool,
}

export default Grid
