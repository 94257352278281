import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import classNames from "classnames"

// import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import { Media } from "components/common"
import { Link } from "gatsby"

const Bold = ({ children }) => <strong className="bold">{children}</strong>
const Italic = ({ children }) => <i className="italic">{children}</i>
const Underline = ({ children }) => <u className="underline">{children}</u>

// IMPORT for custom block.embedded_entry : https://github.com/gatsbyjs/gatsby/discussions/28098

const Text = ({ children, className, as, underline }) => {
  return (
    <StyledText as={as} className={className}>
      {children}
    </StyledText>
  )
}

/**
 * @description Hook for RichText
 *
 * @typedef {{
 * text: {raw: {}}
 * as: 'h1' |'h2'|'h3'|'h4'|'h5'|'p' | 'pSmall'
 * className: String
 * }} Props
 *
 * @type React.FunctionComponent<Props>
 *
 * @returns React Component
 */

const useRichText = ({ text, as, className, underline }) => {
  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
      [MARKS.UNDERLINE]: (text) => <Underline>{text}</Underline>,
      [MARKS.ITALIC]: (text) => <Italic>{text}</Italic>,
      [MARKS.ITALIC]: (text) => <Italic>{text}</Italic>,
    },
    renderNode: {
      // [INLINES.HYPERLINK]: (node, children) => (),
      [INLINES.HYPERLINK]: (node) => {
        const external =
          node.data.uri.startsWith("http") || node.data.uri.startsWith("mailto")

        if (external) {
          return (
            <a href={node.data.uri} target="_blank" rel="noreferrer">
              {node.content[0].value}
            </a>
          )
        }

        return <Link to={node.data.uri}>{node.content[0].value}</Link>
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        const media = node.data.target

        return <Media media={media} />
      },

      [BLOCKS.PARAGRAPH]: (node, children) => {
        return (
          <Text
            as={as === "pSmall" ? "p" : as}
            // className={`${className} ${as === "pSmall" ? "small" : ""}`}
            className={classNames(className, { small: as === "pSmall" })}
          >
            {children}
          </Text>
        )
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const asset = node.data.target

        return <Media media={{ desktop: asset }} />
      },
    },
  }

  if (text && text.raw) {
    return renderRichText(text, options)
  }
  // this is plain text not rich text...
  if (text) {
    return (
      <StyledText
        as={as === "pSmall" ? "p" : as}
        className={`${className} ${as === "pSmall" ? "small" : ""}`}
      >
        {underline ? <span className="animated_underline">{text}</span> : text}
      </StyledText>
    )
  }

  return null
}

const StyledText = styled.p`
  strong.bold {
    font-weight: 500;
  }
`

useRichText.defaultProps = {
  text: "",
  className: "",
  as: "p",
}

useRichText.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.shape({
      raw: PropTypes.string.isRequired,
    }),
    PropTypes.string,
  ]),
  as: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default useRichText
