import { useRef, useState, useLayoutEffect } from "react"

// calculate percentage of where element top and bottom exist on page in percentage...

// usage: const { ref, start, end } = useScrollProgress({ offsetTop, offsetBottom })

function useScrollProgress(props) {
  const { offsetTop, offsetBottom } = props || { offsetTop: 0, offsetBottom: 0 }

  const ref = useRef()
  const [start, setStart] = useState(0)
  const [end, setEnd] = useState(0)
  useLayoutEffect(() => {
    if (!ref.current) {
      return
    }
    const rect = ref.current.getBoundingClientRect()

    const scrollTop = window.pageYOffset || document.documentElement.scrollTop

    const offsetFromTop = rect.top + scrollTop // bottom of element to top of page
    setStart((offsetFromTop - offsetTop) / document.body.clientHeight)
    setEnd(
      (offsetFromTop + rect.height + offsetBottom) / document.body.clientHeight
    ) // bottom of element to height of element below
  }, [offsetTop, offsetBottom])
  return { ref, start, end }
}

export default useScrollProgress
