import React, { useState } from "react"
import styled from "styled-components"
import { Link, Media } from "components/common"
import { device } from "utils"

import MobileOpen from "images/nav/hamburger.inline.svg"

import MobileSideNav from "./MobileSideNav"

const MobileHeader = ({ navigation, logo }) => {
  const [openSideNav, setOpenSideNav] = useState(false)

  return (
    <>
      <StyledNav navLength={navigation.length + 1}>
        <ul className="container">
          <li className="logo">
            <Link to="/">
              <Media media={logo} />
            </Link>
          </li>

          <li></li>

          <li className="mobileOpen">
            <button onClick={() => setOpenSideNav(true)}>
              <MobileOpen />
            </button>
          </li>
        </ul>
      </StyledNav>
      <MobileSideNav
        navigation={navigation}
        open={openSideNav}
        close={() => setOpenSideNav(false)}
      />
    </>
  )
}

const StyledNav = styled.nav`
  @media ${device.laptop} {
    display: none;
  }

  .container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;

    .logo {
      .mobileOpen {
        margin-left: auto;
      }
    }
  }
`
export default MobileHeader
