import React from "react"

import { graphql, useStaticQuery } from "gatsby"

import DesktopFooter from "src/components/navigation/DesktopFooter"

const Footer = () => {
  const { primaryFooter } = useStaticQuery(NAV_QUERY)

  return <DesktopFooter {...primaryFooter} />
}

export const NAV_QUERY = graphql`
  {
    primaryFooter: contentfulNavigation(
      navigationId: { eq: "primary_footer" }
    ) {
      ...Navigation
    }
    # mobileFooter: contentfulNavigation(navigationId: { eq: "mobile_footer" }) {
    #   ...Navigation
    # }
  }
`

export default Footer
