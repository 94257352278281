import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

/**
 * @description Add Margin
 * @typedef {{
 *  children: React.ReactNode
 *  inline: boolean
 *  top: "4" | "8" | "12" | "16" | "24" | "48" | "56" | "64" | "72" | "80" | "112" | "224"
 *  right: "4" | "8" | "12" | "16" | "24" | "48" | "56" | "64" | "72" | "80" | "112" | "224"
 *  bottom: "4" | "8" | "12" | "16" | "24" | "48" | "56" | "64" | "72" | "80" | "112" | "224"
 *  left: "4" | "8" | "12" | "16" | "24" | "48" | "56" | "64" | "72" | "80" | "112" | "224"
 * }}
 *
 * Props
 * @type React.FunctionComponent<Props>
 *
 * @example
 * <Space top="24" />
 * <Space top="24" bottom="12"><p>test</p></Space>
 */

const Margin = props => <StyledMargin {...props}>{props.children}</StyledMargin>

const StyledMargin = styled.div`
  display: ${({ inline }) => (inline ? "inline-block" : "block")};
  margin-top: ${({ top }) => `var(--sp-${top})`};
  margin-right: ${({ right }) => `var(--sp-${right})`};
  margin-bottom: ${({ bottom }) => `var(--sp-${bottom})`};
  margin-left: ${({ left }) => `var(--sp-${left})`};
`

Margin.defaultProps = {
  inline: false,
  top: "0",
  right: "0",
  bottom: "0",
  left: "0",
}

Margin.propTypes = {
  inline: PropTypes.bool,
  top: PropTypes.oneOf([
    "0",
    "4",
    "8",
    "12",
    "16",
    "24",
    "48",
    "56",
    "64",
    "72",
    "80",
    "112",
    "224",
  ]),
  right: PropTypes.oneOf([
    "0",
    "4",
    "8",
    "12",
    "16",
    "24",
    "48",
    "56",
    "64",
    "72",
    "80",
    "112",
    "224",
  ]),
  bottom: PropTypes.oneOf([
    "0",
    "4",
    "8",
    "12",
    "16",
    "24",
    "48",
    "56",
    "64",
    "72",
    "80",
    "112",
    "224",
  ]),
  left: PropTypes.oneOf([
    "0",
    "4",
    "8",
    "12",
    "16",
    "24",
    "48",
    "56",
    "64",
    "72",
    "80",
    "112",
    "224",
  ]),
}

export default Margin
